import React, { useRef } from "react"
import { IdealTabs } from "../../vendor/mui-pro/src/components/CustomTabs/CustomTabs"
import Page from "../page"
import Colors from "../../constants/Colors"
import { makeStyles } from "@material-ui/core"
import { standardPadding } from "../../constants/Spacing"
import clsx from "clsx"


const useStyles = makeStyles(theme => ({
  tabCollectionCardOuter:{
    marginTop: 0,
    transform: props => props.overlap ? `translate(0px, -21px)` : `translate(0px, 60px)`,
    marginBottom: props => props.overlap ? '-27px' : '62px',
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - 16px)`,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  customTabsRoot: {
    transform: `translate(0px, -27px)`,
    marginBottom: '-8px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: "0 !important",
    },
    "& span": {
      marginBottom: "0 !important",
    },
  },
  standardWidgetContainer: {
    ...standardPadding.standardWidgetBottomPadding,
    backgroundColor: Colors.mystic,
    [theme.breakpoints.down('xs')]: {
      ...standardPadding.standardWidgetBottomPaddingMobile,
    },
    "& [ class *= card-body-override]": {
      "& div:last-of-type": {
        marginBottom: 0,
      },
    }
  }
}))


function WidgetTabCollection ({widget, location}){
  const cardColor = (widget && widget.backgroundColor && widget.backgroundColor === "blue") ? Colors.pickledBluewood : Colors.white;

  const cardRef = useRef(null);
  const classes = useStyles({overlap: widget && widget.overlapSectionAbove || false});

  if(!widget) {
    return null;
  }

  let containerStyle = {
    backgroundColor: Colors.mystic,
    paddingBottom: "40px",
  }

  return(<div className={clsx("tab-collection-container-override", classes.standardWidgetContainer)} >
    <IdealTabs
      tabStyle={{ color: "white" }}
      cardClass={classes.tabCollectionCardOuter}
      overlap={widget.overlapSectionAbove}
      noPaddingAndMargin
      location={location}
      noPadding
      bodyColor={cardColor}
      cardRef={cardRef}
      classes={{
        customTabsRoot: classes.customTabsRoot,
        card: classes.tabCollectionCardOuter,
      }}
      tabs={
        widget.tabs.map(function(tab) {
          return {
            tabName: tab.title,
            tabContent: (
              <Page widgets={tab.widgets} />
            )
          }
        })
      }
    />
    </div>
  )
}

export default WidgetTabCollection;
