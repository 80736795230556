import React, {useRef, useState} from 'react';
import Card from "../../vendor/mui-pro/src/components/Card/Card"
import { createStyles, makeStyles } from "@material-ui/core"
import clsx from "clsx"


const useStyles = makeStyles((theme) => createStyles({
  root: {
    width: "138px",
    height: "138px",
    background: "transparent",
    border: "5px solid #cdde68",
    borderRadius: "50%",
    zIndex: 1,
    overflow: "hidden",
    overflowX: "hidden",
    overflowY: "hidden",
    maxWidth: "100%",
    margin: "0 auto",
    transform: "translate(0px, -50%)",
    marginBottom: "-20px"
  },
}));


export default function({image, defaultImage, classes}){
  const baseClasses = useStyles();
  return(
    <div className={classes ? clsx(baseClasses.root, classes.circle) : baseClasses.root} style={{overflowX: "hidden", overflowY: "hidden"}} >
      <img src={image || defaultImage} alt="" />
    </div>
  )
}