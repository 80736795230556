import React, {useState, useEffect} from 'react'

export default function useWindowScroll(){
  const [scrollTop, setWindowScrollTop] = useState(global.pageYOffset); //HACK: will get "window now available" error otherwise

  useEffect(() => {
    const updatePosition = () => {
      setWindowScrollTop(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition)
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition)
  }, [])

  return scrollTop;
}
