import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles, Typography } from "@material-ui/core"
import Colors from "../../constants/Colors"
import Button from "@material-ui/core/Button"
import createStyles from "@material-ui/styles/createStyles/createStyles"
import BaseForm from "./BaseForm"
import { formStyles } from "../../constants/cssMixins"

const gridItemStyles =  makeStyles((theme) => createStyles({
  root: {
    marginBottom: 12,
  },
}));

const responsiveFormStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
    border: "2px solid #4E6489",
    backgroundColor: Colors.mystic,
    height: "44px",
  },
  inputFields: {
    height: "100%",
    fontFamily: "Lato, Verdana, Arial, sans-serif",
  }
}));

const buttonStyles = makeStyles((theme) => createStyles({
  root: {
    border: `2px solid ${Colors.tulipTree}`,
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: "30px",
      paddingLeft: "30px",
      flexGrow: 0,
    },
    backgroundColor: Colors.tulipTree,
    color: Colors.white,
    paddingTop: "12px",
    paddingBottom: "12px",
    marginTop: "10px",
    marginLeft: 0,
    marginRight: 0,
    "&:hover": {
      boxShadow: "0 7px 14px -6px rgba(47, 66, 89, 0.30), 0 2px 11px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2px rgba(47, 66, 89, 0.2)",
      backgroundColor: "#FFA73E",
    }
  },

}));

const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: Colors.catskillWhite,
    paddingBottom: 69,
    paddingTop: 50,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    "& h1": {
      color: Colors.inverted,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    maxWidth: 700,
    margin: "auto",
    backgroundColor: Colors.white,
    padding: "34px 40px",
    borderRadius: 5,
    [theme.breakpoints.down('sm')]:{
      width: "75%",
    },
    [theme.breakpoints.down('xs')]:{
      width: "95%",
      paddingLeft: 10,
      paddingRight: 10,
      minWidth: 0,
    },
  },
  label: {
    color: Colors.kashmirBlue,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    fontSize: 14,
  },
  input:{
    backgroundColor: Colors.white,
    border: `1px solid red`,
    borderRadius: 6,
  },
  outlined: {
    border: `25px solid red`,
  },
  focused:{
    border: `1px solid red`,
  },
  customInput: {
    ...formStyles.customInput,
    paddingLeft: 10,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    "&::placeholder": {
      //paddingTop: 6,
    },
    "&::active": {
      paddingLeft: 10,
    }
  },
  error: {
    fontFamily: "Lato, Verdana, Arial, sans-serif",
   color: "red",
   fontSize: 12,
    height: 12,
  },
  errorInput: {
    ...formStyles.errorInput,
    paddingLeft: 10,
  },
  success: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    margin: "auto",
    padding: "34px 40px",
    backgroundColor: Colors.white,
    borderRadius: 6,
    //textAlign: "center",
    marginTop: 50,
    marginBottom: 69,
  },
  helpText:{
    "&:placeholder": {

    }
  },
  errorNotice: {
    flexDirection: "column",
    width: "100%",
    margin: "auto",
    backgroundColor: "#FFF0EE",
    borderRadius: 6,
    padding: 12,
    border: "2px solid red",
    fontSize: 14,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    marginBottom: theme.spacing(1),
    color: "red",
    display: "inline",
  }
}));


const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default ({ widget }) => {
  const classes = useStyles();
  const itemClasses = gridItemStyles();
  const formClasses = responsiveFormStyles();
  const responsiveButtonClasses = buttonStyles();

  return (
    <BaseForm widget={widget} className={classes.root} classes={classes}
    validation={values => {
      let errors = {};
      if (!values.phone) {
        errors.phone = 'Required';
      }

      if (!values.location) {
        errors.location = 'Required';
      }

      if (!values.subject) {
        errors.subject = 'Required';
      }

      if (!values.message) {
        errors.message = 'Required';
      }

      if (values.email) {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
      }

      return errors;
    }}>
      {({ isSubmitting, submitForm, status, errors}) => (
        <div className={classes.container} >
          {
            !(Object.keys(errors).length === 0 && errors.constructor === Object) &&
              <div className={classes.errorNotice}>
                <span style={{display: "inline"}}>Please fill the following fields:  </span>
                  {
                    Object.keys(errors).map((key) => {
                      return(<span>{key}, </span>)
                    })
                  }
              </div>
          }
          <Grid item xs={12} classes={{root: itemClasses.root}} >
            <FormControl fullWidth={true}>
              <label className={classes.label}>Your Name</label>
              <Field name="name"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"First and Last"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="name" /></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Your Email</label>
              <Field  name="email" type="email"
                     render= {
                       ({field}) => {
                         return(
                           <input
                             {...field}
                             className={errors && errors.email ? classes.errorInput : classes.customInput}
                             placeholder={"your@email.com"}
                           />
                         )
                       }
                     }
              >
              </Field>
              <div className={classes.error}><ErrorMessage  name="email"></ErrorMessage></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Your Phone Number</label>
              <Field name="phone"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"(555) 555-5555"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="phone" /></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>City, State</label>
              <Field name="location"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"Ex: Spokane, Wa"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="location" /></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Subject</label>
              <Field name="subject"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"Why did you choose to contact us?"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="subject" /></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Your Message</label>
              <Field name="message"
                render={
                  ({ field }) => {
                    return (
                      <textarea
                        rows="4"
                        {...field}
                        className={classes.customInput}
                        style={{height: "auto"}}
                        placeholder={"We'd love to hear from you."}
                      />
                    )
                  }
                }>
              </Field>
              <div className={classes.error}><ErrorMessage name="message" /></div>
            </FormControl>
          </Grid>

          <Button onClick={submitForm} className={responsiveButtonClasses.root} disabled={isSubmitting}>
            <Typography variant="button">
             Send
            </Typography>
          </Button>
        </div>
      )}
    </BaseForm>
  )
}
