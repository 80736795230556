import React from 'react'
import { container } from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  contentBlockContainer: {
    ...container,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }
  },
  noCard: {
  },
  carded: {
  },
  marginDown: {
  }
}))

export default ({widget, carded}) => {
  const classes = useStyles();
  return(
    <div className={clsx("html__insert", classes.contentBlockContainer, carded ? classes.carded : classes.noCard, classes.marginDown)} dangerouslySetInnerHTML={{__html: widget.body }}></div>
  )

}