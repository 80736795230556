import React from "react"

import Grid from "@material-ui/core/Grid/index"
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import CircleImage from "../partials/CircleImage"
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import Img from "gatsby-image"
import Colors from "../../constants/Colors"
import {Link} from 'gatsby';
import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button"
import { detectExternalLink, getRouteForLocatable, useAllPageData } from "../../hooks/routing"
import { launch88, jumpToSection} from "../../hooks/routing"
import { standardPadding } from "../../constants/Spacing"
import clsx from "clsx"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const useStyles = makeStyles((theme) => ({
  image : {
    //height: "112px",
    width: "112px",
    //border: "8px solid #9EAB51",
    //borderRadius: "97px",
    //transform: "translate(0px, -30%)",
    margin: "auto",
    marginBottom: 10,
  },
  factoidTypography: {
    display: "inline-block",
    textAlign: "center",
    width: "100% !important",
    marginBottom: 10,
    "& h3": {
      margin: "auto",
    }
  },
  iconContainer: {
    marginTop: theme.spacing(1),
    "& >div:last-of-type":{
      [theme.breakpoints.down('sm')]:{
        marginBottom: 0,
      },
    },
  },
  iconGridItems: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
      marginBottom: theme.spacing(3),
    },
  },
  buttonText: {
    margin: "auto",
  },
  iconGridItemBody: {
    fontSize: 14,
    lineHeight: "18px",
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      lineHeight: "20px",
    }
  },
  iconGridOuterAdjust: {
    ...standardPadding.standardWidgetBottom,
  },
  iconGridForceMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(5),
    },
  },
}));

export default ({widget}) => {
  const classes = useStyles(widget);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const pageData = useAllPageData();

  const optionalButton = () => {
    //build a regular link
    if(!widget.optionalButtonAdvancedSettings){
      return(
        <Link to={getRouteForLocatable(pageData, widget.optionalLink)} className={classes.buttonText} style={{textDecoration: "none"}}>
          <Button color="primary" large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
            <Typography variant="button">
              {widget.optionalLinkTitle}
            </Typography>
          </Button>
        </Link>
      )
    } else if (widget.optionalButtonAdvancedSettings && widget.optionalButtonAdvancedAction === "External Link" && detectExternalLink(widget.optionalButtonAdvancedLink)){
      return(
        <a href={widget.optionalButtonAdvancedLink} className={classes.buttonText} target="_blank" rel="noopener" style={{textDecoration: "none"}}>
          <Button color="primary" large className={classes.buttonText} styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
            <Typography variant="button">
              {widget.optionalLinkTitle}
            </Typography>
          </Button>
        </a>
      )
    } else if (widget.optionalButtonAdvancedSettings && widget.optionalButtonAdvancedAction === "Phone Call") {
      return (
        <a href={`tel:${widget.optionalButtonAdvancedLink}`} className={classes.buttonText} rel="noopener" style={{textDecoration: "none"}}>
          <Button color="primary" large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
            <Typography variant="button">
              {widget.optionalLinkTitle}
            </Typography>
          </Button>
        </a>
      )
    } else if (widget.optionalButtonAdvancedSettings && widget.optionalButtonAdvancedAction === "Scroll To Section") {
      return(
        <Button onClick={() => {jumpToSection(widget.optionalButtonAdvancedLink, mobile)}} className={classes.buttonText} color="primary" large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
          <Typography variant="button">
            {widget.optionalLinkTitle}
          </Typography>
        </Button>
      )
    } else if (widget.optionalButtonAdvancedSettings && widget.optionalButtonAdvancedAction === "Live Chat") {
      return(
        <Button onClick={launch88} color="primary" className={classes.buttonText} large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
          <Typography variant="button">
            {widget.optionalLinkTitle}
          </Typography>
        </Button>
      )
    } else {
      return(
        <Button color="primary" large className={classes.buttonText} styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
          <Typography variant="button">
            {widget.optionalLinkTitle}
          </Typography>
        </Button>
      )
    }
  }


  return (
    <div className={classes.iconGridOuterAdjust} style={{ ...container}}>
      {
        widget.subtitle &&
          <div style={{marginBottom: (!!widget.title ? 8 : 20) + "px" }}>
            <Typography variant="h5">{widget.subtitle}</Typography>
          </div>
      }
      {
        widget.title &&
          <div>
            <Typography variant="h2">{widget.title}</Typography>
          </div>
      }
      {
        widget.description &&
          <div className={clsx("html__insert", classes.iconGridForceMargin)} dangerouslySetInnerHTML={{__html: widget.description}} />
      }
      <Grid container spacing={2} className={classes.iconContainer}>
        {
          !!(widget.gridItems) && widget.gridItems.map(function(f) {
            return (
              <Grid item xs={12} md={12 / widget.gridItems.length || 0} className={classes.iconGridItems}>
                {
                  !!(f.image && f.image.fluid) &&
                  <Img fluid={f && f.image && f.image.fluid} className={classes.image} />
                }
                <div className={classes.factoidTypography}>
                  <Typography variant="h3" style={{color: Colors.tundora, width: "100%"}}>
                    {f.title}
                  </Typography>
                </div>
                <div className={classes.factoidTypography}>
                  <Typography className={classes.iconGridItemBody} variant="body1" >{f.description}</Typography>
                </div>
              </Grid>
            )
          })
        }
      </Grid>
      {
        !!(widget.optionalLinkTitle) &&
        <div style={{display: "flex", paddingTop: 24}}>
          {optionalButton()}
        </div>
      }
    </div>
  )
}
