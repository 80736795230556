import React, { useState, useEffect } from "react";
import CircleProfileCard from "../CircleProfileCard"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/styles"
import useTheme from "@material-ui/core/styles/useTheme"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import {useMediaQuery} from "@material-ui/core"
import Colors from "../../constants/Colors"
import clsx from "clsx"

const useStyles = makeStyles((theme) => createStyles({

  root: {
    padding: 0,
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      "& div:last-of-type": {
        marginBottom: 0,
      }
    }
  },
  innerRoot: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  imageContainer: {
    width: "150px",
    margin: "auto",
    height: "150px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  smallHeightAdjust: {
    marginBottom: 80,
  }
}))


export default ({widget}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [rendered, setRender] = useState(false);
  const mediumBreakpoint = useMediaQuery(theme.breakpoints.up('md'))


  useEffect(() => {
    setRender(true);
  })

  return (
    <div className={classes.root}>
      {
        !rendered &&
          <div style={{height: "760px"}}>
          </div>
      }
      {
        (rendered && mediumBreakpoint) &&
        widget.teamLeaders.map(teamLeader => (
          <div className={classes.innerRoot}>
            <CircleProfileCard
              horizontal
              image={teamLeader.image}
              description={teamLeader.bio}
              jobTitle={teamLeader.jobTitle} name={teamLeader.name}
              profileCardClasses={classes}
            />
          </div>
        ))
      }
      {
        (rendered && !mediumBreakpoint) &&
        widget.teamLeaders.map(teamLeader => (
          <div className={clsx(classes.innerRoot, classes.smallHeightAdjust)}>
            <CircleProfileCard
              image={teamLeader.image}
              description={teamLeader.bio}
              jobTitle={teamLeader.jobTitle} name={teamLeader.name}
              profileCardClasses={classes}
            />
          </div>
        ))
      }
    </div>
  )
}
