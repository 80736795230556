import React, {useRef, useState} from 'react';
import Card from "../vendor/mui-pro/src/components/Card/Card"
import { createStyles, makeStyles, Typography } from "@material-ui/core"
import CircleImage from "./partials/CircleImage"
import useTheme from "@material-ui/core/styles/useTheme"
import Modal from "./Modal"
import quoteImg from "../assets/img/testimonial-bgd2.png"
import Colors from "../constants/Colors"


const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]:{
      marginBottom: "20px",
    },
    [theme.breakpoints.up("md")]: {
      height: "100%",
      transform: "translate(0px, -15px)",
    },
    display: "flex", flexDirection: "column", alignItems: "center",
    "& button": {
      marginTop: theme.spacing(2),
    },
    "& img": {
      "-webkit-transform": "perspective(1)",
    }
  },
  cardBg:{
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.up('md')]:{
      backgroundSize: "40%",
    },
    [theme.breakpoints.down('sm')]:{
      backgroundSize: "25%",
    },
    "& p": {
      marginBottom: 0,
    },
  },
  testimonialVisibleCard: {
    backgroundImage: `url(${quoteImg})`,
    backgroundSize: "31%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "0px 46px",
    height: "100%",
    paddingLeft: "38px",
    paddingRight: "38px",
    paddingBottom: "32px",
    marginTop: "15px",
    [theme.breakpoints.up('md')]: {
      marginBottom: 31,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  circle: {
    width: "138px",
    height: "138px",
    background: "transparent",
    border: "5px solid #cdde68",
    borderRadius: "50%",
    zIndex: 1,
    overflow: "hidden",
    overflowX: "hidden",
    overflowY: "hidden",
    maxWidth: "100%",
    margin: "0 auto",
    transform: "translate(0px, -50%)",
    marginBottom: "-20px"
  },
  quotationImg: {
    maxWidth: "10%",
    zIndex: 0,
    position: "absolute",
    left: "5%",
  },
}));

function TestimonialCard(props){
  const {text, image, patientName, modalText, defaultImage } = props;
  const classes = useStyles();
  const theme = useTheme();

  return(
    <div className={classes.root}>
      <Card profile className={classes.testimonialVisibleCard}>
        <CircleImage
          image={image}
          defaultImage={defaultImage}
          classes={classes.circle}
        />
        <div style={{maxWidth: "100%", height: "0 10px"}}>
          <Typography variant="h4" style={{marginTop: "-30px", textTransform: "uppercase"}}>{patientName}</Typography>
        </div>
        {
          text &&
          <div className={classes.cardBg}>
            <div style={{...theme.typography.body1, fontSize: 18, color: Colors.scorpion, fontWeight: "bold", fontStyle: "italic"}} dangerouslySetInnerHTML={{__html: text}} />
          </div>
        }
        {
          modalText &&
            <div>
              <Modal
                buttonTextColor={Colors.azure}
                buttonText={"Read More"}
                title={patientName || "Anonymous"}
                modalText={modalText}
                color={"white"}
              />
            </div>

        }
      </Card>
    </div>
  )
}

export default TestimonialCard;