import React from 'react'
import Grid from "@material-ui/core/Grid"
import Img from 'gatsby-image'
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from "clsx"
import { standardPadding } from "../../constants/Spacing"


const useStyles = makeStyles(theme => ({
  imageTextListOuterContainer: {
    ...standardPadding.standardWidgetBottom
  },
  text: {
    margin: "auto",
    paddingLeft: 15,
  },
  lastItemNoMargin: {
    "& > :last-child": {
      marginBottom: 0
    }
  }
}))

export default ({widget}) => {
  const classes = useStyles();

  return(
  <Grid container className={classes.imageTextListOuterContainer}>
    {
      widget.imageTextBlocks.map(iTB => (
        <Grid item container style={{minHeight: 100, padding: 10}}>
          {
            !!(iTB.image && iTB.image.fluid) &&
            <Grid item xs={2} style={{
              minWidth: 116,
              maxWidth: 116
            }}>
              <Img
                style={{
                  width: '100%',
                  position: "relative",
                  top: "50%",
                  transform: `translateY(-50%)`,
                }}
                fluid={iTB.image && iTB.image.fluid ? iTB.image.fluid : null}
                alt="..."
              />
            </Grid>
          }
          {
            !(iTB.image && iTB.image.fluid) &&
              <div style={{maxWidth: 116, minWidth: 116}}>

              </div>
          }
          <Grid item xs={10}
                className={classes.text}
            style={{
              maxWidth: `calc(100% - 116px)`,
            }}>
            <div className={clsx(classes.lastItemNoMargin, "html__insert", "image_text_list_overrides", iTB.image ? null : "image_text_list_full")}
                 dangerouslySetInnerHTML={{__html: iTB.text }}
                 style={{display: "flex", margin: "auto"}}
            ></div>
          </Grid>
        </Grid>
      ))
    }
  </Grid>
)
}
