import React from 'react';

import Card from "../vendor/mui-pro/src/components/Card/Card.jsx";
import CardBody from "../vendor/mui-pro/src/components/Card/CardBody.jsx";
import CardHeader from "../vendor/mui-pro/src/components/Card/CardHeader.jsx";

import cardsStyle from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react/views/componentsSections/sectionCards.jsx";
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles, Typography } from "@material-ui/core"
import Img from "gatsby-image"
import clsx from "clsx"
import Colors from "../constants/Colors"

const useStyles = makeStyles((theme) => createStyles( {
  ...cardsStyle,
  root :{
    [theme.breakpoints.up("md")]: {
      maxWidth: "360px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
    }
  },
  cardRoot: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 42,
    }
  },
  cardDescription: {
    ...theme.typography.body1,
    fontWeight: "bold",
    fontStyle: "italic"
  },
  cardTitle: {

  },
  cardProfile: {
    [theme.breakpoints.down('xs')]:{
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  root__horizontal: {
    marginLeft: 0,
    flexDirection: "row",
    minHeight: 200,
    width: "calc(100% - 75px)",
    transform: "translateX(75px)",
    maxWidth: "none",
    paddingBottom: 8,
    "& [class *= \"CardBody\"]": {
      //paddingBottom: theme.spacing(1),
      paddingBottom: 0,
    },
  },
  ody__verticaloverride: {
   paddingBottom: 0,
  },
  card__verticalGreyText: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    }
  },
  card__horizontal: {
    marginBottom: 12,
  },
  imageHeader__horizontal: {
    marginTop: "-60px",
    marginLeft: -13,
    marginRight: 0,
  },
  header__horizontal: {
    transform: "translate(-75px, 50%)",
    marginRight: "-51px !important"
  },
  body__horizontal: {
    flexGrow: 0,
    paddingLeft: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "calc(100% + 53px)",
    textAlign: "left",
    [theme.breakpoints.up('md')]: {
      paddingRight: 0,
    }
  },
  horizontalText: {
    //padding: `0.9375rem 1.875rem`,
    paddingRight: 0,
  },
  body1: {
    ...theme.typography.body1,
    color: Colors.grayChateau
  },
  italicBody: {
    color: "#565656",
    fontFamily: "Calluna",
    fontSize: 18,
    fontStyle: "italic",
    fontWeight: "bold",
    lineHeight: "26px",
    marginTop: 12,
  },
}));


export default function CircleProfileCard({name, jobTitle, description, image, profileCardClasses, horizontal, italicBody}) {
  const classes = useStyles();
  return (
    <Card profile className={clsx(classes.root, horizontal ? classes.root__horizontal : null)}
      classes={{
        cardProfile: classes.cardProfile,
        card: horizontal ? null : classes.cardRoot,
      }}
    >
      <CardHeader className={ horizontal ? classes.imageHeader__horizontal : null} image>
        <div className={clsx(profileCardClasses.imageContainer, horizontal ? classes.header__horizontal : null)}>
          {
            image && image.fluid &&
            <Img
              className={profileCardClasses.image}
              fluid={image && image.fluid ? image.fluid : null}
              alt="..."
            />
          }

        </div>
      </CardHeader>
      <CardBody className={ horizontal ? classes.body__horizontal : classes.body__verticaloverride} style={{flexGrow: 0}}>
        <Typography variant="subtitle1" className={clsx(classes.cardTitle, profileCardClasses.providerName)}>{name}</Typography>
        <Typography variant="h6"
          className={clsx(`${classes.cardCategory}`, horizontal ? classes.card__horizontal : classes.card__verticalGreyText)} style={{textTransform: "uppercase", fontSize: 16, color: Colors.grayChateau}}>
          {jobTitle}
        </Typography>
        {
          horizontal &&
          <div className={clsx("html__insert", classes.horizontalText)} style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: description}} />
        }
      </CardBody>
      {
        !horizontal &&
        <div className={clsx("html__insert", italicBody ? classes.italicBody : null)} style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html: description}} />
      }
    </Card>
  );
}
