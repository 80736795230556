import React from "react"
import { Link } from "gatsby"
import {useAllPageData, getRouteForLocatable} from '../hooks/routing'
import Colors from "../constants/Colors"

export default (props) => {
  let {datoItem, className, classes} = props
  const allPageData = useAllPageData()
  if (datoItem && getRouteForLocatable(allPageData,datoItem)) {
    return (
      <Link to={ getRouteForLocatable(allPageData,datoItem)} className={classes.navStyle} activeStyle={{ color: Colors.white }}>
        {props.children}
      </Link>
    )
  }
  return (
    <div>{props.children}</div>
  )
}
