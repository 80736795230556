import React, { useRef } from "react"
import BackgroundImage from "gatsby-background-image"
import Page from "../page"
import Colors from "../../constants/Colors"
import Card from "../../vendor/mui-pro/src/components/Card/Card.jsx";
import CardBody from "../../vendor/mui-pro/src/components/Card/CardBody.jsx";
import { makeStyles, Typography } from "@material-ui/core"
import { container, containerWithoutPadding } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import clsx from "clsx"
import "../../constants/overrides.scss"
import { standardPadding } from "../../constants/Spacing"
import useTheme from "@material-ui/core/styles/useTheme"
import { Link } from "gatsby"
import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button"


export const contentContainerStyles = makeStyles(theme => ({
  cardOuter: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    ...standardPadding.standardWidgetBottomPadding,
  },
  card_60:{
    width: "60%",
    maxWidth: 700,
    [theme.breakpoints.down('sm')]:{
      maxWidth: "100%",
      width: "100%",
    },
    [theme.breakpoints.down('xs')]:{
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  card_40:{
    maxWidth: "40%",
    //maxWidth: 400,
    [theme.breakpoints.down('sm')]:{
      maxWidth: "100%",
    },
    [theme.breakpoints.down('xs')]:{
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  overlap:{
    //paddingBottom: 22,
  },
  card: {
    padding: "40px 20px 48px 20px",
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }
  },
  overlapCard: {
    marginTop: 0,
    transform: "translate(0px, -54px)",
    marginBottom: "-54px",
  },
  noOverlap: {
    paddingTop: 64,
  },
  noOverlapCard: {
    margin: "0px auto",
  },
  withBackgroundColor: {
    backgroundColor: props => props.backgroundColorPicker && props.backgroundColorPicker.hex ? props.backgroundColorPicker.hex : Colors.white,
  },
  cardlessContainer: {
    width: props => props.width ? props.width : "100%",
    ...standardPadding.standardWidgetBottomPadding,
    ...standardPadding.standardWidgetTopPadding,
    "@media screen and (max-width: 600px)": {
      paddingBottom: 32,
      paddingTop: 32,
    }
    //paddingTop: theme.spacing(8),
  },
  cardless_60: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
    }
  },
  cardless_40: {
    [theme.breakpoints.down('sm')]: {
      width: "100% !important",
    }
  },
}))

export default ({widget, location, withButton}) => {
  console.log("widget data received is: ", widget);
  const cardColor = (widget && widget.cardBackgroundColor && widget.cardBackgroundColor.toLowerCase() === "dark blue") ? Colors.pickledBluewood : Colors.white;
  const classes = contentContainerStyles(widget);
  const cardRef = useRef(null);
  const overlapAbove = !!(widget.overlapSectionAbove);
  const theme = useTheme()
  const appliedWidth = () => {
    if(widget.width === "60%"){
      return widget.enableCardStyle ? classes.card_60 :classes.cardless_60
    } else if (widget.width === "40%") {
      return widget.enableCardStyle ? classes.card_40 :classes.cardless_40
    } else {
      return null;
    }
  }

  if(!widget) {
    return null;
  }


  let cardlessContainerStyle = {
    ...container,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  }

  let content;

  if(widget.enableCardStyle) {
    content = (
      <div className={clsx(
        overlapAbove ? classes.overlap : classes.noOverlap,
        classes.cardOuter,
        classes.withBackgroundColor,
      )} >
        <Card
          className={clsx(appliedWidth(), overlapAbove ? classes.overlapCard : classes.noOverlapCard, classes.card)}
          classes={{
            card: classes.card
          }}
          style={{ backgroundColor: cardColor,
            width: `${widget.width}%`,
          }}
          overrideIdentifier="card-container-identifier"
        >
          <CardBody noPadding>
            <Page widgets={widget.widgets} carded={true}/>
          </CardBody>
        </Card>
        {
          withButton &&
          <div style={{display: "flex", justifyContent: "center", paddingTop: 50}}>
            <Link to={"/clinics"} style={{textDecoration: "none"}}>
              <Button color="secondary">
                <Typography variant="button">Back</Typography>
              </Button>
            </Link>
          </div>
        }
      </div>
    )
  } else {
    content = (
      <div style={cardlessContainerStyle}>
        <div className={clsx(classes.cardlessContainer, appliedWidth(), "cardless-container-identifier")}>
          <Page widgets={widget.widgets} carded={false}/>
        </div>
      </div>
    )
  }

  if (widget.enableBackgroundImage) {
    return (
      <BackgroundImage
        className={classes.image}
        tag="div"
        fluid={widget.backgroundImage && widget.backgroundImage.fluid}
      >
        {content}
      </BackgroundImage>
    )
  } else {
    return content;
  }
}
