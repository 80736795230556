import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import NavigationNode from "../navigation-node"
import { Button, Typography, Grid } from "@material-ui/core"
import {useAllPageData, getRouteForLocatable} from '../../hooks/routing'
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/styles"
import Colors from "../../constants/Colors"
import Card from "../../vendor/mui-pro/src/components/Card/Card"
import Accordion from "../../vendor/mui-pro/src/components/Accordion/Accordion.jsx";
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import { standardPadding } from "../../constants/Spacing"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/core/styles/useTheme"

const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: Colors.catskillWhite,
    minHeight: 200,
    paddingTop: theme.spacing(5),
    //paddingBottom: theme.spacing(4),
    ...standardPadding.standardWidgetBottomPadding,
  },
  navigation: {
    ...container,
    marginBottom: -90,
    marginTop: 10,
    [theme.breakpoints.down('xs')]:{
      marginBottom: 1,
    },
    [theme.breakpoints.up("sm")]: {
      marginBottom: -15,
    },
  },
  navigationNode:{
    display: "inline-block",
    ...container,
  },
  container: {
    flexDirection: "column",
    ...container,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  header: {
    height: "fit-content",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: 18,
    paddingBottom: theme.spacing(3),
    marginLeft: -19,
    marginTop: 16,
    borderRadius: 5,
    backgroundColor: Colors.kashmirBlue,
    zIndex: 2,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginLeft: -39,
    },
    [theme.breakpoints.up("sm")]: {
      width: 225,
      marginLeft: -38,
      marginTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "auto",
      marginTop: -34,

    },
    "& > a": {
      textDecoration: "none",
      display: "block",
      textAlign: "center",
      "& > button": {
        color: Colors.inverted,
        textAlign: "center",
      },
    },
  },
  headerGrid:{
    zIndex: 2,
  },
  contentGrid: {
    [theme.breakpoints.up("xs")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("sm")]:{
      display: "flex",
      minWidth: "60%",
    },
    [theme.breakpoints.up("md")]: {
      width: "100%",
      minWidth: "75%",
    },
  },
  card: {
    alignItems: "flex-end",
    alignSelf: "flex-end",
    flexDirection: "row",
    paddingBottom: 30,
    [theme.breakpoints.up("sm")]: {
      width: "93%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  accordionRoot: {
    marginLeft: "20px",
    marginRight: "30px",
    width: "80%",
    paddingTop: "10px",
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(4),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: `calc(100% - 16px)`,
    },
    [theme.breakpoints.up("sm")]: {
      paddingTop: "32px",
    },
  },
  expansionPanelSummary: {
    color: Colors.azure,
    padding: "20px 10px 5px 0px",
  },
  ExpansionPanelSummaryExpanded:{
    color: `${Colors.oliveGreen} !important`,
  },
  expansionPanel: {
    marginBottom: 5,
  },
  cardRootClasses: {
    marginBottom: 0,
  },
  onState: {
    backgroundColor: Colors.lynch,
  }
}))


export default ({page, widget, navigation, location}) => {
  const allPageData = useAllPageData()

  const data = useStaticQuery(graphql`
    query SiteSettingsQuery {
      datoCmsSetting {
        knowledgeBaseNavigation {
          ...NavigationNodeFields
        }
      }
    }
  `)
  const { knowledgeBaseNavigation } = data.datoCmsSetting;
  const classes = useStyles();
  const theme = useTheme();
  const extraSmallBreakpoint = useMediaQuery(theme.breakpoints.down('xs'))
  const hashScroll = () => {
    const anchor = document.getElementById("accordion-start");
    document.getElementById('gatsby-focus-wrapper').scrollTo(0, anchor.offsetTop);
  }
  const onState = (link) => {
    return location && location.pathname.split('/')[location.pathname.split('/').length - 1] === link;
  }

  useEffect(() => {
     if(location && location.pathname && extraSmallBreakpoint){
       if(location.pathname.split("/").length > 2){
         hashScroll();
       }
     }
  });

  return (
    <div className={classes.root}>
      <Grid className={classes.container} container>
        <Grid item xs={12} className={classes.headerGrid}>
          { location.pathname !== getRouteForLocatable(allPageData, knowledgeBaseNavigation.link) &&
          <div className={classes.navigation}>
            <NavigationNode className={classes.navigationNode} classes={{ navStyle: "" }} datoItem={knowledgeBaseNavigation.link}>
              <Typography variant="h6" style={{display: "inline-block", color: Colors.kashmirBlue}}>{knowledgeBaseNavigation.label+ " "} </Typography>
            </NavigationNode>
            <Typography variant="h6" style={{display: "inline-block"}}>&nbsp;/ {page.title}</Typography>
          </div>
          }
        </Grid>
        <Card className={classes.card} classes={{
          card: classes.cardRootClasses
        }}>
          <Grid item xs={12} className={classes.contentGrid}>
              <ul className={classes.header}>
                {
                  knowledgeBaseNavigation.treeChildren.map(node => (
                    <NavigationNode datoItem={node.link} classes={{ navStyle: ""}} >
                      <Button className={onState(node.link.slug) ? classes.onState : null}>
                        <Typography style={{margin: "2px 0px"}} variant="button">{node.label}</Typography>
                      </Button>
                    </NavigationNode>
                  ))
                }
              </ul>
            <Accordion
              single={false}
              active={0}
              activeColor={"oliveGreen"}
              title={widget.title}
              classes={{
                root: classes.accordionRoot,
                expansionPanelSummary: classes.expansionPanelSummary,
                oliveGreenExpansionPanelSummaryExpanded: classes.ExpansionPanelSummaryExpanded,
                expansionPanel: classes.expansionPanel,
              }}
              collapses={
                widget.articles.map(article => (
                  {
                    title: article.title,
                    content: article.body,
                  }
                ))
              }
              />
          </Grid>
        </Card>
      </Grid>
    </div>
  )
}
