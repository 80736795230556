import React, {useRef, useState} from 'react';
import ReactPlayer from 'react-player'

import GridContainer from "../vendor/mui-pro/src/components/Grid/GridContainer"
import GridItem from "../vendor/mui-pro/src/components/Grid/GridItem" // import css
import TestimonialCard from './TestimonialCard'
import { CustomButton } from "./CustomButtons/CustomButton"
import { makeStyles, Typography } from "@material-ui/core"
import { createStyles } from "@material-ui/styles"
import Colors from "../constants/Colors"
import { container } from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react.jsx"
import {Link} from "gatsby"

import bgImg from "../assets/img/testimonials-bg_2x.png"
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import clsx from "clsx"
import { card } from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react"

const useStyles = makeStyles((theme) => createStyles({
  root: {
    ...container,
    backgroundColor: "transparent",
    paddingTop: 70,
    [theme.breakpoints.up('md')]:{
      paddingTop: 70,
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: 42,
    },
  },
  background: {
    "&:before": {
      width: "32%",
      height: "21%",
    }
  },
  threePlus: {
    [theme.breakpoints.up('md')]: {
      marginTop: 52,
    }
  },
  videoBottom: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: `34px !important`,
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    }
  },
  lastCard: {
    [theme.breakpoints.down('sm')]:{
      marginBottom: "0 !important",
    }
  },
  headerContainer: {
    textAlign: "center",
    "& + .GridCardToOffset": {
      paddingTop: 40,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 680,
      "& + .GridCardToOffset + .GridCardToOffset": {
        paddingTop: 40,
      }
    },
  },
  item: {
    [theme.breakpoints.down("sm")]:{
      marginBottom: "32px",
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  featureVideo: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  featurePlayerContainer: {
    paddingTop: "56.25%",
    position: "relative",
    marginBottom: 8,
  },
  caption: {
    height: 24,
    textAlign: "center",
    width: "100%"
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0
  },
  tripleVideo: {
    //marginBottom: "80px",
    marginBottom: "104px",
    [theme.breakpoints.up("md")]:{
      maxHeight: "300px"
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  bottomButtonStyles: {
    [theme.breakpoints.down('sm')]:{
      marginTop: "-36px",
    },
  },
  htmlBlockCenter: {
    textAlign: "center",
    margin: "auto",
    [theme.breakpoints.up('sm')]:{
      marginBottom: theme.spacing(6),
      maxWidth: "100%",
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 38,
      marginLeft: theme.spacing(1),
      maxWidth: "100% !important"
    },
  },
}))


function Testimonials ({widget: {header, introSentence, contentBlocks, linkButton, linkButtonText}, settings: {defaultStoryImage}}){
  const defaultImage = (defaultStoryImage && defaultStoryImage.url) || (defaultStoryImage && defaultStoryImage.fluid && defaultStoryImage.fluid.src) || null;
  const classes = useStyles();
  return (
    <div id="testimonials-section">
    <div
      style={{
        backgroundImage: `url('${bgImg}')`,
        display: "cover",
        backgroundSize: "34%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "100% 0%",
        backgroundColor: "#9EAB51",
        "&:before": {
          width: "32%",
          height: "21%",
        }
      }}
    >
    <GridContainer className={classes.root} justify="center">
      <GridItem xs={12} className={classes.headerContainer}>
        <Typography variant="h2" style={{color: Colors.inverted}}>
          {header}
        </Typography>
        <div className={clsx("html__insert html__subtitle_force html__force_center inverted", classes.htmlBlockCenter)} dangerouslySetInnerHTML={{ __html: introSentence }}
          style={{maxWidth: "calc(100vw - 40%)"}}
        ></div>
      </GridItem>
      {
        contentBlocks.map((block, idx) => {
          if(block.__typename === "DatoCmsFeatureVideo" ){
            return(
              <GridItem xs={12} className={clsx(classes.videoBottom, classes.item)} style={{marginBottom: "50px"}}>
                <div className={classes.featurePlayerContainer}>
                  <ReactPlayer
                    width='100%'
                    height='100%'
                    className={classes.featureVideo}
                    poster={block.video.videoFile && block.video.videoFile.thumbnailUrl}
                    url={block.video && block.video.videoFile && block.video.videoFile.url || null}
                  />
                </div>
                <GridItem xs={12} className={classes.item} style={{marginBottom: "40px"}}>
                  <div className={classes.caption} style={{textAlign: "center", width: "100%"}}>
                    <Typography variant="h6">
                      {block.video.videoCaption}
                    </Typography>
                  </div>
                </GridItem>
              </GridItem>
            )
          }else if (block.__typename === "DatoCmsStoryCardCollection"){
            return(
              block.cards.map((c, cardIndex) => {
                const threePlus = (contentBlocks[idx].cards.length > 2 )
                const applyThreePlusStyle = threePlus && cardIndex > 1
                const lastCard = cardIndex === contentBlocks[idx].cards.length - 1;
                return(
                  <GridItem xs={12} md={6} className={clsx(classes.item,
                    applyThreePlusStyle ? classes.threePlus : "GridCardToOffset",
                    lastCard ? classes.lastCard : null
                  )}>
                    <TestimonialCard
                      image={c.patientImage && c.patientImage.fluid && c.patientImage.fluid.src || null}
                      patientName={c.patientName}
                      text={c.excerpt}
                      modalText={c.patientQuote}
                      defaultImage={defaultImage}
                    />
                  </GridItem>
                )
              })
            )
          } else if (block.__typename === "DatoCmsThreeVideoSection"){
            return(
              <GridContainer xs={12} className={classes.videoBottom}>
                {
                  block.videos.map((vid) => {
                    return(
                      <GridItem xs={12} md={4} l={-2} className={clsx(classes.tripleVideo, classes.item)}>
                        <div className={classes.featurePlayerContainer}>
                          <ReactPlayer
                            className={classes.featureVideo}
                            width="100%"
                            height="100%"
                            url={vid.videoFile && vid.videoFile.url || null}
                            poster={vid.videoFile && vid.videoFile.thumbnailUrl}
                          />
                        </div>
                        <div className={classes.caption}>
                          <Typography variant="h6">
                            {vid.videoCaption}
                          </Typography>
                        </div>
                      </GridItem>
                    )
                  })
                }
              </GridContainer>
            )
          }
        })

      }
      { linkButton &&
        <GridItem className={classes.bottomButtonStyles} style={{display: "flex"}} xs={12} md={4} justify="center">
          <Link to={linkButton.slug} style={{textDecoration: "none"}}>
            <Button color="secondary" >
              <Typography variant="button" style={{color: Colors.azure}}>
               {linkButtonText || "View More"}
              </Typography>
            </Button>
          </Link>
        </GridItem>
      }
    </GridContainer>
    </div>
    </div>
    )
}

export default Testimonials;