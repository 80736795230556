import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles, Typography } from "@material-ui/core"
import Colors from "../../constants/Colors"
import createStyles from "@material-ui/styles/createStyles/createStyles"
import BaseForm from "./BaseForm"
import { formStyles } from "../../constants/cssMixins"
import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button"
import ReCAPTCHA from 'react-google-recaptcha';

const responsiveFormStyles = makeStyles((theme) => createStyles({
    root: {
        [theme.breakpoints.down('xs')]: {
            flexGrow: 1,
        },
        border: "2px solid #4E6489",
        backgroundColor: Colors.white,
        height: "44px",
    },
    inputFields: {
        ...formStyles.customInput,
        paddingLeft: 10,
        "&::placeholder": {
            paddingLeft: 0,
        }
    },
    error: {
        color: "red",
        fontSize: "12px",
        height: 17,
        width: "fit-content",
    },
    errorMessage: {
        paddingLeft: 2,
        paddingRight: 2,
        backgroundColor: Colors.errorBg,
        fontFamily: "Lato, Verdana, Arial, sans-serif",
    },
    success: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        margin: "auto",
        padding: "34px 40px",
        backgroundColor: Colors.white,
        borderRadius: 6,
        //textAlign: "center",
        border: `1px solid ${Colors.azure}`,
        "& p": {
        },
        "& h2": {
        }
    },
    errorInput: {
        ...formStyles.errorInput,
        paddingLeft: 10,
    },
}));

const buttonStyles = makeStyles((theme) => createStyles({
    root: {
        [theme.breakpoints.down('xs')]: {
            width: `calc(100% - 10px)`,
        },
        [theme.breakpoints.up('sm')]: {
            paddingRight: "30px",
            paddingLeft: "30px",
            flexGrow: 0,
            marginLeft: "5px",
            marginRight: "5px",
        },
        backgroundColor: Colors.tulipTree,
        color: Colors.white,
        paddingTop: "12px",
        paddingBottom: "12px",
        marginLeft: "5px",
        marginRight: "5px"
    }
}));

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default ({ widget }) => {
    const formClasses = responsiveFormStyles();
    const responsiveButtonClasses = buttonStyles();
    const recaptchaRef = React.createRef();

    return (
        <BaseForm widget={widget} classes={formClasses} validation={values => {
            let errors = {};
            if (!values.name) {
                errors.name = 'Required';
            }
            if (!values.email) {
                errors.email = 'Required';
            } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
                errors.email = 'Invalid email address';
            }
            if (!values.Question) {
                errors.Question = 'Required';
            }
            return errors;
        }}>
            {({ isSubmitting, submitForm, status, errors }) => (
                <div>
                    <Grid item xs={12}  >
                        <Typography variant="body1" style={{
                            color: Colors.inverted, fontWeight: "bold", marginBottom: "10px", fontSize: "20px"
                        }}>
                            {widget.title}
                        </Typography>
                        <FormControl fullWidth={true}>
                            <Field name="name"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={errors && errors.name ? formClasses.errorInput : formClasses.inputFields}
                                        placeholder="Name"
                                    />
                                )}
                            />
                            <div className={formClasses.error}><p><ErrorMessage name="name" /></p></div>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} >
                        <FormControl fullWidth={true}>
                            <Field name="email" type="email"
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        className={errors && errors.email ? formClasses.errorInput : formClasses.inputFields}
                                        placeholder={"your@email.com"}
                                    />
                                )}
                            />
                            <div className={formClasses.error}>
                                <ErrorMessage className={formClasses.errorMessage} name="email" render={msg => <div className={formClasses.errorMessage}>{msg}</div>} />
                            </div>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl fullWidth={true}>
                            <Field className={formClasses.inputFields} type="phone" name="phone" placeholder="Phone" />
                            <div className={formClasses.error}><ErrorMessage name="phone" /></div>
                        </FormControl>
                    </Grid>

                    {/* Honeypot field */}
                    <div style={{ position: "absolute", left: "-9999px" }}>
                        <Field className={formClasses.inputFields} name='required-field' placeholder="required field" />
                    </div>

                    <Grid item xs={12} style={{ marginBottom: 12 }}>
                        <FormControl fullWidth={true}>
                            <Field name="Question"
                                render={({ field }) => (
                                    <textarea
                                        {...field}
                                        placeholder="Questions?"
                                        rows="4"
                                        className={errors && errors.Question ? formClasses.errorInput : formClasses.inputFields}
                                        style={{ height: "auto" }}
                                    />
                                )}
                            />
                            <div className={formClasses.error}><p><ErrorMessage name="Question" /></p></div>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Field name="contactType"
                            render={({ field }) => {
                                return (
                                    <React.Fragment>
                                        <label><Typography
                                            style={{ color: Colors.white }}
                                        >How should we contact you?</Typography></label>
                                        <div style={{ paddingLeft: 16, marginBottom: 12 }}>
                                            <div style={{ display: "flex", alignItems: "baseline", height: 25 }}>
                                                <input type="radio" {...field} id="Text" value="Text"
                                                    name="contactType" style={{ marginRight: 8 }}
                                                />
                                                <label htmlFor="virtual">
                                                    <Typography style={{ color: Colors.white }}>
                                                        Text
                                                    </Typography>
                                                </label>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "baseline", height: 25 }}>
                                                <input type="radio" {...field} id="Phone" value="Phone"
                                                    name="contactType" style={{ marginRight: 8 }}
                                                />
                                                <label htmlFor="clinic">
                                                    <Typography style={{ color: Colors.white }}>
                                                        Phone
                                                    </Typography>
                                                </label>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "baseline", height: 25 }}>
                                                <input type="radio" {...field} id="Email" value="Email"
                                                    name="contactType" style={{ marginRight: 8 }}
                                                />
                                                <label htmlFor="no preference">
                                                    <Typography style={{ color: Colors.white }}>
                                                        Email
                                                    </Typography>
                                                </label>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            }}>
                        </Field>
                    </Grid>
                    <div style={{ margin: '5px' }}>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Lcmbv4pAAAAANil-sDLVTmChq0lPn53t2uPe0RC"
                            onChange={value => console.log("Captcha value:", value)}
                        />
                    </div>
                    <Button color="primary" onClick={() => {
                        const captchaValue = recaptchaRef.current.getValue();
                        if (!captchaValue) {
                            alert('Please verify that you are not a robot.');
                            return;
                        }
                        submitForm();
                    }} className={responsiveButtonClasses.root} disabled={isSubmitting}>
                        <Typography variant="button">
                            Submit
                        </Typography>
                    </Button>
                </div>
            )}
        </BaseForm>
    )
}
