import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

// original asset pattern I was't able to makle work '^https://www.datocms-assets.com/.*.(jpg%7Cpng)$';
const imgAssetPattern = '^https://www.datocms-assets.com/.*';

const ModifiedHelmet = ({ seo, favicon, children, ...rest }) => React.createElement(
  Helmet,
  rest,
  (seo ? seo.tags : [])
    .concat(favicon ? favicon.tags : [])
    .map((item, i) => React.createElement(
      item.tagName,
      Object.assign(
        { key: `helmet-datocms-${i}` },
        Object.entries(item.attributes || {})
          .reduce((acc, [name, value]) => {
            if (value) {
              let val = value;
              if (RegExp(imgAssetPattern).test(value)) {
                val += '?w=1200';
              }
              acc[name] = val;
            }
            return acc;
          }, {}),
      ),
      item.content,
    )).concat(children),
);

ModifiedHelmet.propTypes = {
  children: PropTypes.any,
  favicon: PropTypes.shape({
    tags: PropTypes.array,
  }),
  seo: PropTypes.shape({
    tags: PropTypes.array,
  }),
};

export default ModifiedHelmet;
