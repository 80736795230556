import React from 'react';
import Footer from "../vendor/mui-pro/src/components/Footer/Footer"
import footerStyle from '../vendor/mui-pro/src/assets/jss/material-kit-pro-react/components/footerStyle.jsx'
import makeStyles from "@material-ui/core/styles/makeStyles"
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import classNames from "classnames";
import FindClinic from "./FindClinic"
import clsx from "clsx";
import Colors from "../constants/Colors"
import {Link} from "gatsby"
import { createStyles, Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { launch88 } from "../hooks/routing"

import idlWhite from "../assets/img/ideal-option_white-logo.png"
import { detectExternalLink, getRouteForLocatable, useAllPageData } from "../hooks/routing"

const baseStyles = makeStyles(footerStyle);
const composedStyles = makeStyles((theme) => createStyles({
  root: {
    "-webkit-font-smoothing": "antialiased",
    backgroundColor: Colors.pickledBluewood,
    width: "100%",
    [theme.breakpoints.up('md')]:{
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    }
  },
  container: {
    [theme.breakpoints.up("md")]:{
      maxWidth: "100vw",
    },
  },
  header: {
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    float: "left",
  },
  headers: {
    color: Colors.inverted,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    fontWeight: "bold",
    textDecoration: "none"
  },
  item: {
    [theme.breakpoints.down("sm")]:{
      padding: 0,
    },
    [theme.breakpoints.down("xs")]:{
      marginBottom: 20,
    }
  },
  mapItem: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
    }
  },
  listItemIndent:{
    marginLeft: 15,
    marginBottom: 0,

    [theme.breakpoints.down('xs')]:{
      marginLeft: 20,
    },
  },
  logo: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "46%",
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: "60%",
      paddingLeft: 0,
    }
  },
  iconButton: {
    padding: 0,
    marginLeft: 10,
    backgroundColor: Colors.pickledBluewood,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 20,
    },
    "& > span": {
      height: 41,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  headerHack: {
      minHeight: 50,
      textAlign: "left",
    "& > span > *" : {
      marginBottom: "0 !important",
    },
    "& > span > a > *": {
      marginBottom: "0 !important",
    },
    [theme.breakpoints.down('xs')]: {
      "& > a": {
      },
    },
    [theme.breakpoints.up('md')]: {
      width: "100%",
    },
  },
  headerHackThree: {
    "& button": {
      float: "left",
    }
  },
  thirdSection:{
    "& li ": {
    }
  },
  hoverNav: {
    width: "fit-content",
    whiteSpace: "pre-wrap",
    "&:hover": {
      backgroundColor: Colors.lynch,
      color: Colors.white,
      boxShadow:`0 7px 14px -6px rgba(47, 66, 89, 0.30),0 2px 11px 0px rgba(0, 0, 0, 0.12),0 4px 5px -2px rgba(47, 66, 89, 0.2)`,
    },
  },
  icons: {
    color: Colors.white,
    "&:before": {
      color: `${Colors.white} !important`,
    }
  },
  aboveCopyright: {
    display: "inline-block",
    color: Colors.inverted,
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    "&:hover": {

    }
  },
  aboveCopyrightContainer: {
    justifyContent: "space-between",
  },
  footerLinkImage: {
    display: "flex",
    alignItems: "center",
  },
  aboveCopyrightContactLinks: {
    [theme.breakpoints.up('md')]: {
      display: "flex",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down('sm')]:{
      display: "flex",
      justifyContent: "flex-start",
    }
  },
  bottomFooterMargin: {
    [theme.breakpoints.up("md")]: {
      marginBottom: "20px",
    },
    [theme.breakpoints.down('sm')]: {
      "&>div:first-of-type": {
        marginLeft: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  footerContainer: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  containerSpacerDesktop: {
    [theme.breakpoints.up('sm')]:{
      marginTop: theme.spacing(2)
    }
  },
  alignmentPadding: {
    paddingLeft: 14,
  }
}));

export default function({data}){
  // console.log("footer data is: ", data)
  const classes = baseStyles();
  const addClasses = composedStyles();
  const allPageData = useAllPageData;

  const linksCountOne = data.footerNav1Sublinks && data.footerNav1Sublinks.length || 0;
  const linksCountTwo = data.footerNav2Sublinks && data.footerNav2Sublinks.length || 0;
  const linksCountThree = data.footerNav3Sublinks && data.footerNav3Sublinks.length || 0;

  // console.log("footer is: ", data)

  return(
    <Footer
      theme="white"
      className={clsx(classes.root, addClasses.root)}
      classes={{
        container: addClasses.footerContainer
      }}
      content={
        <div>
          <Grid container spacing={2} className={addClasses.aboveCopyrightContainer}>
            <Grid item container alignItems="center" xs={12} md={3}>
              <Link to="/" className={addClasses.footerLinkImage}>
                <img src={idlWhite} alt="ideal-option" className={classNames(addClasses.logo, addClasses.bottomFooterMargin)}/>
              </Link>
            </Grid >
            <Grid item container className={addClasses.bottomFooterMargin} alignItems="center" xs={12} md={3}>
              <a href={data.twitterUrl} target="_blank" rel="noopener">
                <Button className={addClasses.iconButton} color="twitter" justIcon round>
                  <div className="clearfix pbs" style={{width: "100%", height: "100%"}}>
                    <svg className={clsx("icon", "icon-twitter", "social1")} style={{width: "100%", height: "100%"}}>
                      <use xlinkHref="#icon-twitter"></use>
                    </svg>
                  </div>                <i className={"fab fa-twitter"}/>
                </Button>
              </a>
              <a href={data.linkedinUrl} target="_blank" rel="noopener">
                <Button className={addClasses.iconButton} color="linkedin" justIcon round>
                  <div className="clearfix pbs" style={{width: "100%", height: "100%"}}>
                    <svg style={{width: "100%", height: "100%"}} class="icon icon-linkedin">
                      <use xlinkHref="#icon-linkedin"></use>
                    </svg>
                  </div>
                </Button>
              </a>
              <a href={data.facebookUrl} target="_blank" rel="noopener">
              <Button className={addClasses.iconButton} color="facebook" justIcon round>
                <div className="clearfix pbs" style={{width: "100%", height: "100%"}}>
                  <svg className={clsx("icon", "icon-facebook", "social1")} style={{width: "100%", height: "100%"}}>
                    <use xlinkHref="#icon-facebook"></use>
                  </svg>
                </div>
                <i className={"fab fa-facebook"}/>
              </Button>
              </a>
              <a href={data.vimeoUrl}  target="_blank" rel="noopener">
                <Button className={addClasses.iconButton} color="vimeo" justIcon round>
                  <div className="clearfix pbs" style={{width: "100%", height: "100%"}}>
                    <svg className={clsx("icon", "icon-vimeo", "social1")} style={{width: "100%", height: "100%"}}>
                      <svg className="icon icon-vimeo">
                        <use xlinkHref="#icon-vimeo"></use>
                      </svg>
                    </svg>
                  </div>
                  <i className={"fab fa-facebook"}/>
                </Button>
              </a>
            </Grid >
            <Grid item className={clsx(addClasses.bottomFooterMargin, addClasses.aboveCopyrightContactLinks)} xs={12} sm={12} md={6}>
              {
                data.subFooterSublinks &&
                  data.subFooterSublinks.map(link => {
                    return(
                      <div className={addClasses.aboveCopyright}>
                        {
                          link.link &&
                          <Link to={getRouteForLocatable(allPageData(), link.link)} className={addClasses.headers} style={{color: Colors.inverted}} variant="h6" >
                            {link.label}
                          </Link>
                        }
                      </div>
                    )
                  })
              }
            </Grid >
            <Grid item container xs={12}>
              <div
                className={classNames(classes.pullCenter, classes.copyRight)}
                style={{textAlign: "left"}}
              >
                <Typography variant="h6" style={{color: Colors.grayChateau, fontWeight: "no rmal"}}>
                Copyright &copy; {1900 + new Date().getYear()}{" "} Ideal Option. All Rights Reserved.
                <br/>
                  SUBOXONE® is a registered trademark of Indivior UK Limited. VIVITROL® is a registered trademark of Alkermes, Inc.
                </Typography>
              </div>
            </Grid >
          </Grid>
        </div>
      }
    >
      <div className={clsx(classes.footer)}>
        <Grid container>
          <Grid container md={8} >
            <Grid item className={addClasses.item} xs={12} sm={linksCountOne ? 4 : 1} md={linksCountOne ? 4 : 1}>
            <div className={addClasses.headerHack}>
                  {
                    data.footerNav1ExternalLink &&
                    <a style={{textDecoration: "none"}} target="_blank" href={data.footerNav1ExternalLink}>
                      <Button color="footer" size="sm" className={addClasses.hoverNav}>
                        <Typography variant="h3" style={{color: Colors.inverted, textAlign: "left", marginBottom: 0 }} >
                          {data.footerNav1Label}
                       </Typography>
                      </Button>
                    </a>
                  }
              {
                data.footerNav1Link &&
                <Link style={{textDecoration: "none"}} to={getRouteForLocatable(allPageData(), data.footerNav1Link) || "/"}>
                  <Button color="footer" size="sm" className={addClasses.hoverNav}>
                    <Typography variant="h3" style={{color: Colors.inverted, textAlign: "left", marginBottom: 0 }} >
                      {data.footerNav1Label}
                    </Typography>
                  </Button>
                </Link>
              }
            </div>
            {
            data.footerNav1Sublinks.map(cat => {
              return(
                <ul style={{textAlign: "left", marginLeft: 0, lineHeight: "28px"}}>
                  <li className={addClasses.listItemIndent}>
                    {
                      cat.externalLink &&
                      <a href={cat.externalLink} target="_blank" style={{color: Colors.inverted, lineSpacing: "28pt", textDecoration: "none"}}>
                        <Button className={addClasses.hoverNav} color="footer" size="sm">
                          <Typography variant="button" style={{textDecoration: "none"}}>
                            {cat.label}
                          </Typography>
                        </Button>
                      </a>
                    }
                    {
                      cat.link &&
                      <Link to={getRouteForLocatable(allPageData(), cat.link)} variant="h6" style={{color: Colors.inverted, lineSpacing: "28pt", textDecoration: "none"}}>
                        <Button className={addClasses.hoverNav} color="footer" size="sm">
                          <Typography variant="button" style={{textDecoration: "none"}}>
                            {cat.label}
                          </Typography>
                        </Button>
                      </Link>
                    }
                  </li>
                </ul>
              )
            })
          }
          </Grid>
            <Grid item className={addClasses.item} xs={12} sm={4} md={linksCountTwo ? 4 : 1}>
            <div className={addClasses.headerHack}>
                {
                  data.footerNav2ExternalLink &&
                  <a style={{textDecoration: "none"}} target="_blank" href={data.footerNav2ExternalLink}>
                    <Button color="footer" size="sm" className={addClasses.hoverNav}>
                      <Typography variant="h3" style={{color: Colors.inverted, textAlign: "left", marginBottom: 0 }} >
                        {data.footerNav2Label}
                      </Typography>
                    </Button>
                  </a>
                }
                {
                  !data.footerNav2externalLink &&
                  <Link style={{textDecoration: "none"}} to={getRouteForLocatable(allPageData(), data.footerNav2Link) || "/"}>
                    <Button color="footer" size="sm" className={addClasses.hoverNav}>
                      <Typography variant="h3" style={{color: Colors.inverted, textAlign: "left", marginBottom: 0 }} >
                        {data.footerNav2Label}
                      </Typography>
                    </Button>
                  </Link>
                }
            </div>
            {
              data.footerNav2Sublinks.map(cat => {
                return(
                  <ul style={{textAlign: "left", marginLeft: 0, lineHeight: "28px"}}>
                    <li className={addClasses.listItemIndent}>
                      {
                        cat.externalLink &&
                        <a href={cat.externalLink} target="_blank" style={{color: Colors.inverted, lineSpacing: "28pt", textDecoration: "none"}}>
                          <Button className={addClasses.hoverNav} color="footer" size="sm">
                            <Typography variant="button" style={{textDecoration: "none"}}>
                              {cat.label}
                            </Typography>
                          </Button>
                        </a>
                      }
                      {
                        cat.link &&
                        <Link to={getRouteForLocatable(allPageData(), cat.link)} variant="h6" style={{color: Colors.inverted, lineSpacing: "28pt", textDecoration: "none"}}>
                          <Button className={addClasses.hoverNav} color="footer" size="sm">
                            <Typography variant="button" style={{textDecoration: "none"}}>
                              {cat.label}
                            </Typography>
                          </Button>
                        </Link>
                      }
                    </li>
                  </ul>
                )
              })
            }
          </Grid>
            <Grid item className={clsx(addClasses.item, addClasses.thirdSection)} xs={12} sm={linksCountThree ? 4 : 1} md={linksCountThree ? 4 : 1}>
            <div className={addClasses.headerHack}>
              <Button color="footer" size="sm" className={addClasses.hoverNav}>
                {
                  !data.footerNav3ExternalLink &&
                  <Link style={{textDecoration: "none"}} to={getRouteForLocatable(allPageData(), data.footerNav3Link) || "/"}>
                    <Typography variant="h3" style={{color: Colors.inverted, textAlign: "left", marginBottom: 0 }} >
                      {data.footerNav3Label}
                    </Typography>
                  </Link>
                }
                {
                  data.footerNav3ExternalLink &&
                  <a style={{textDecoration: "none"}} target="_blank" href={`"${data.footer3ExternalNavLink}"`}>
                    <Typography variant="h3" style={{color: Colors.inverted, textAlign: "left", marginBottom: 0 }} >
                      {data.footerNav3Label}
                    </Typography>
                  </a>
                }
             </Button>
            </div>
            {
              data.footerNav3Sublinks.map(cat => {
                return(
                  <ul style={{textAlign: "left", marginLeft: 0, lineHeight: "28px"}}>
                    <li className={addClasses.listItemIndent}>
                      {
                        cat.externalLink &&
                        <a href={cat.externalLink} target="_blank" style={{color: Colors.inverted, lineSpacing: "28pt", textDecoration: "none"}}>
                          <Button className={addClasses.hoverNav} color="footer" size="sm">
                           <Typography variant="button" style={{textDecoration: "none"}}>
                              {cat.label}
                            </Typography>
                          </Button>
                        </a>
                      }
                      {
                        cat.link &&
                        <Link to={getRouteForLocatable(allPageData(), cat.link)} variant="h6" style={{color: Colors.inverted, lineSpacing: "28pt", textDecoration: "none"}}>
                          <Button className={addClasses.hoverNav} color="footer" size="sm">
                            <Typography variant="button" style={{textDecoration: "none"}}>
                              {cat.label}
                           </Typography>
                          </Button>
                        </Link>
                      }
                    </li>
                  </ul>
                )
              })
            }
          </Grid>
            <Grid className={clsx(addClasses.alignmentPadding, addClasses.containerSpacerDesktop)} item md={12} style={{height: 56}}>
                <div dangerouslySetInnerHTML={{__html: data.translationNotice}} />
            </Grid>
          </Grid>
          <Grid item className={clsx(addClasses.item, addClasses.mapItem)} xs={12} md={4}>
            <FindClinic />
          </Grid >
        </Grid>
      </div>
    </Footer>

  )

}
