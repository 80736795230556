/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar/index";
import Toolbar from "@material-ui/core/Toolbar/index";
import IconButton from "@material-ui/core/IconButton/index";
import Button from "@material-ui/core/Button/index";
import Hidden from "@material-ui/core/Hidden/index";
import Drawer from "@material-ui/core/Drawer/index";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
// core components
import headerStyle from "../../assets/jss/material-kit-pro-react/components/headerStyle.jsx";
import clsx from "clsx"
import Colors from "../../../../../constants/Colors"
import {Link} from "gatsby"

import bgImg from "../../../../../assets/img/idea-option_color-logo.png";


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.headerColorChange = this.headerColorChange.bind(this);
  }
  handleDrawerToggle() {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  }
  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener("scroll", this.headerColorChange);
    }
  }
  headerColorChange() {
    const { classes, color, changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  }
  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener("scroll", this.headerColorChange);
    }
  }
  render() {
    const { classes,
      color,
      links,
      brand,
      fixed,
      absolute,
      sticky,
      disableGutters,
      offset,
      searchButtonOnClick,
      mobileOpen,
      searchOpen,
      heightHack,
      secondary,
      displayDrawer,
    } = this.props;
    const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: sticky ? Colors.white : color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
      [classes.sticky]: sticky,
      [classes.heightHack]: heightHack,
      [classes.offset]: offset //added class to indicate Sticky nav is below another sticky nav
    });
    return (
      <AppBar className={clsx(appBarClasses)}>
        <Toolbar className={classes.container} disableGutters={!!disableGutters}>
          <Button className={clsx(classes.title, classes.logoContainer, "focus-ripple-override")}>
            {
              brand
            }
          </Button>
          <Hidden smDown implementation="css" className={clsx(classes.hidden, mobileOpen ? "display-none-override" : null)}>
            <div className={classes.collapse}>{links}</div>
          </Hidden>
          <Hidden >
            {
              displayDrawer &&
              <IconButton
                className={secondary ? "blue-fill-override" : null}
                color="inherit"
                aria-label="open drawer"
                onClick={searchButtonOnClick}
              >
                <Menu />
              </IconButton>
            }
          </Hidden>
        </Toolbar>
        <Hidden implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            paperAnchorRight={{
              backgroundColor: "orange"
            }}
            PaperProps={{
              classes: {
                root: classes.paperRoot
              }
            }}
            onClose={searchButtonOnClick}
          >
            <div className={clsx(classes.appResponsive, classes.customDrawerStyles)}
              style={{height: 60}}
            >
              <div>
                <Button className={clsx(classes.drawerButtonStyle, "home-button-hook")} to="/">
                      <img id="home-link" style={{height: "70%",
                        marginTop: 10,
                       }} src={bgImg} />
                  </Button>
                {/*<Close/>*/}
                <div style={{width: 27}}></div>
              </div>
              <div style={{display: mobileOpen ? "inherit" : "none"}}>
              {links}
              </div>
            </div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={searchButtonOnClick}
              className={classes.closeButtonDrawer}
              style={{
                marginTop: 16,
                color: Colors.azure
              }}
            >
              <Close />
            </IconButton>
          </Drawer>
        </Hidden>
        <Hidden implementation="js">
          <Drawer
            className="search-drawer"
            variant="temporary"
            anchor={"right"}
            open={searchOpen}>

          </Drawer>
        </Hidden>
      </AppBar>
    );
  }
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  links: PropTypes.node,
  brand: PropTypes.string || PropTypes.object,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // this.props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // this.props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};

export default withStyles(headerStyle)(Header);
