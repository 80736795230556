import React from 'react';
import { Formik, Form, Field } from 'formik';
import clsx from "clsx"


const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}


export default ({ widget, children, className, validation, classes}) => {
  return (
    <Formik
      validate={validation}
      initialValues={{ name: '', email: '', phone: '', "required-field": '' }}
      onSubmit={(values, { setSubmitting, resetForm, setStatus }) => {
        setTimeout(() => {
          fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": widget.formId, ...values })
          })
            .then(() => {
              resetForm();
              setSubmitting(false);
              setStatus({ message: "success" });
            })
            .catch(error => alert(error));
        }, 400);
      }}
    >
      {({ isSubmitting, submitForm, status, errors }) => {
        if (status && status.message && status.message === 'success') {
          if (typeof window !== 'undefined') {
            window.dataLayer.push('event', 'conversion', {'send_to': process.env.GATSBY_GOOGLE_TAGMANAGER_ID + '/jtjDCO3vkJ4BEOO03t0D'})
          }
          return (
            <div className={clsx(classes && classes.success, "html__insert")} dangerouslySetInnerHTML={{__html: widget.successMessage}}/>
          )
        } else {
          return (
            <Form name={widget.formId} data-netlify="true" data-netlify-honeypot="required-field"
                  className={className}
            style={{marginBottom: 0, width: '100%'}}>
              {children({ isSubmitting, submitForm, status, errors })}
            </Form>
          )
        }
      }}
    </Formik>
  )
}
