import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Colors from "../constants/Colors"
import { CustomButton } from "./CustomButtons/CustomButton"
import { Typography } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import clsx from "clsx"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { jumpToSection, useAllPageData } from "../hooks/routing"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: Colors.brandyPunch,
    height: 48,
    zIndex: 5000,
    boxShadow: "none",
    marginBottom: -120,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hotline: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  toolbarRoot: {
    height: 65,
    padding: 0,
  },
  gridRoot: {
    height: "100%"
  },
  formControl: {
    color: Colors.white,
    width: "100%",
  },
  linkContainer: {
    textTransform: "uppercase",
    display: "flex",
    height: "100%",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
    },
  },
  contactUsStyles: {
    [theme.breakpoints.up('md')]: {
      maxWidth: 200,
    },
  },
  lightBg: {
    backgroundColor: Colors.tulipTree,
    [theme.breakpoints.up("md")]: {
      maxWidth: 200,
    }
  },
  selectRoot: {
    paddingRight: 16,
    borderBottom: "none",
    margin: 0,
    padding: 0
  },
  icons:{
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 5,
    color: Colors.white,

  },
  contactLabel: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginLeft: "15%",
      transform: 'translate(12px, 17px) scale(1) !important',
      display: "flex",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "34%",
      transform: 'translate(12px, 16px) scale(1) !important',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: "27%",
    },
    "@media (min-width: 360px) and (max-width: 600px)": {
      marginLeft: "20%",
    },
    "@media (max-width: 442px)": {
      marginLeft: "12%",
    }
  },
  chatIcon: {
    "&::before":{
      content: "\e902",
    }
  },
  dropdownIcon: {
    display: "none"
  },
  title: {
    flexGrow: 1,
  },
  selectMenu: {
    height: "100%",
    "&:focus": {
      backgroundColor: `${Colors.brandyPunch} !important`,
    },
  },
  popMenu: {
    "& li": {
      color: Colors.azure,
    },

  },
  popBase: {
    marginTop: 32,
    [theme.breakpoints.down('sm')]:{
      left: "0 !important",
    }
  },
  dropdownSvg: {
    transform: `translateY(-3px)`,
  },
  findAClinicButton: {
    boxShadow: "none",
    marginLeft: 50,
    padding: 0,
    marginTop: 0,
    marginBottom: 0
  },
  gutters: {
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

export default function QuickLinks(props){
  const classes = useStyles();

  const {hotline} = props;
  function handleChange(e) {
    if(e.target.value === "call"){
        window.location = `tel:${hotline}`;
    } else if (e.target.value === "chat"){
      props.triggerChat();
    } else if (e.target.value === "back"){
      jumpToSection("#ContactForm", props.extraSmallBreakpoint);
    }

  }

  return(
    <AppBar disableGutters
            position={"sticky"}
      classes={{
        root: classes.root
      }}
    >
      <Toolbar variant="dense" className={classes.toolbarRoot}>
        <Grid container direction="row" justifyContent="center" className={classes.gridRoot}>
        {
          hotline &&
            <Grid item xs={0} md={3} className={classes.hotline}>
              <CustomButton color={Colors.brandyPunch} style={{boxShadow: "none", marginLeft: 50, padding: 0, marginTop: 0, marginBottom: 0, borderRadius: 0}}>
                <Typography variant="button" style={{fontSize: 16}}>
                  <a href={`tel:${hotline}`}
                     style={{textDecoration: "none",
                       color: Colors.white
                   }}>
                    {hotline}
                  </a>
                </Typography>
              </CustomButton>
            </Grid>
        }
            <Grid container xs={12} md={9} justifyContent="center" direction="row" className={classes.linkContainer}>
            <Grid item xs={6} md={4} className={clsx(classes.linkContainer, classes.contactUsStyles)}>
              <FormControl variant="filled" className={classes.formControl}
                classes={{
                  root: classes.formControl
                }}
              >
                <InputLabel
                  className={classes.contactLabel}
                  htmlFor="filled-age-simple"
                  style={{marginBottom: "0 !important"}}
                >
                  <span className={clsx("icon-chat_bubble_outline", classes.icons, classes.chatIcon)}></span>
                  <Typography variant="button" style={{fontSize: 11, color: Colors.white, textTransform: "uppercase"}}>
                    Contact Us
                  </Typography>
                  <svg
                    className={classes.dropdownSvg}
                    style={{width: "20px",
                    height: "17px"}}>
                    <path d="M7 10l5 5 5-5z" fill={Colors.white}></path>
                  </svg>
                </InputLabel>
                <Select
                  className="quick-links-css"
                  value=""
                  MenuProps={{
                    PaperProps: {
                      className: classes.popBase,
                    },
                    MenuListProps: {
                      className: classes.popMenu,
                    }
                  }}

                  inputProps={{
                    style: {
                      marginTop: 0,
                      paddingBottom: 0,
                      height: "100%",
                    }
                  }}
                  onChange={handleChange}
                  disableUnderline
                  classes={{
                    root: classes.selectRoot,
                    icon: classes.dropdownIcon,
                    selectMenu: classes.selectMenu,
                  }}
                >
                  <MenuItem value="call" classes={{
                    gutters: classes.gutters,
                  }}>
                    <Typography variant="button">
                      Call us: {hotline}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="back" classes={{
                    gutters: classes.gutters,
                  }}>
                    <Typography variant="button">
                      Request a call back
                    </Typography>
                  </MenuItem>
                  <a href='/contact-us' style={{textDecoration: "none"}}>
                    <MenuItem value="back" classes={{
                      gutters: classes.gutters,
                    }}>
                      <Typography variant="button">
                        Send us a message
                      </Typography>
                    </MenuItem>
                  </a>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={4} className={clsx(classes.linkContainer, classes.lightBg)}>
              <CustomButton style={{boxShadow: "none", width: "100%", marginBottom: 0, marginTop: 0, borderRadius: 0}} link="/find-a-clinic">
                <span style={{
                  marginRight: "8px",
                  fontSize: "16px",
                }} class="icon-location_on-outline-24px"></span>
                <Typography variant="button"
                  style={{fontSize: 11, textTransform: "uppercase"}}
                >
                  Find A Clinic
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )

}