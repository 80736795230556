import React from 'react'
import clinicImage from '../assets/img/map-graphic2.jpg'
import {Link} from 'gatsby';
import { createStyles, Typography } from "@material-ui/core"
import { CustomButton } from "./CustomButtons/CustomButton"
import Colors from "../constants/Colors"
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import makeStyles from "@material-ui/core/styles/makeStyles"


const useStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.up('sm')]: {
      width: `100%`,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${theme.spacing(2)}px)`,
      float: "right",
    },

  },
}))

export default function(){
  const classes = useStyles();

    return(
      <div
        className={classes.root}
        style={{
          backgroundImage: `url(${clinicImage})`,
          backgroundSize: "cover",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          minHeight: "200px"
        }}
      >
        <div style={{
          alignSelf: "flex-start",
          transform: `translate(20px, -20px)`,
        }}>
          <Typography variant="subtitle1" style={{color: Colors.inverted, marginBottom: 16}} >
            Find a clinic near you
          </Typography>
          <Link
            to="/find-a-clinic"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            <Button color="info" style={{float: "left"}}>
              <Typography variant="button">
                Find a clinic
              </Typography>
            </Button>
          </Link>
        </div>
      </div>
    )

}
