import { makeStyles } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles/createStyles"
import Colors from "../constants/Colors"
import { formStyles } from "../constants/cssMixins"


export const mainStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: Colors.catskillWhite,
    paddingBottom: 69,
    paddingTop: 50,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    "& h1": {
      color: Colors.inverted,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    maxWidth: 700,
    margin: "auto",
    backgroundColor: Colors.white,
    padding: "34px 40px",
    borderRadius: 5,
    [theme.breakpoints.down('sm')]:{
      width: "75%",
    },
    [theme.breakpoints.down('xs')]:{
      width: "95%",
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  label: {
    color: Colors.kashmirBlue,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    fontSize: 14,
  },
  input:{
    backgroundColor: Colors.white,
    border: `1px solid red`,
    borderRadius: 6,
  },
  outlined: {
    border: `25px solid red`,
  },
  focused:{
    border: `1px solid red`,
  },
  customInput: {
    ...formStyles.customInput,
    paddingLeft: 10,
    "&::placeholder": {
    },
    "&::active": {
      paddingLeft: 10,
    }
  },
  error: {
    color: "red",
    fontSize: 12,
  },
  errorInput: {
    ...formStyles.errorInput,
    paddingLeft: 10,
  },
  success: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    margin: "auto",
    padding: "34px 40px",
    backgroundColor: Colors.white,
    borderRadius: 6,
    //textAlign: "center",
    marginTop: 50,
    marginBottom: 69,
    "& p": {
    },
  },
  helpText:{
    "&:placeholder": {

    }
  }
}));

export const gridItemStyles =  makeStyles((theme) => createStyles({
  root: {
    marginBottom: 24,
  },
}));

export const buttonStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: "30px",
      paddingLeft: "30px",
      flexGrow: 0,
      marginLeft: "5px",
      marginRight: "5px",
    },
    backgroundColor: Colors.tulipTree,
    color: Colors.white,
    paddingTop: "12px",
    paddingBottom: "12px",
    marginTop: "20px",
    marginLeft: "5px",
    marginRight: "5px"
  }
}))