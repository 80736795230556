import React from "react"

import GridContainer from "../vendor/mui-pro/src/components/Grid/GridContainer.jsx";
import GridItem from "../vendor/mui-pro/src/components/Grid/GridItem.jsx";
import { Typography, useMediaQuery } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import theme from '../constants/Theme'
import contentStyle from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react/views/presentationSections/contentStyle"
import clsx from "clsx"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import { detectExternalLink, getRouteForLocatable, useAllPageData } from "../hooks/routing"
import {navigate, Link} from "gatsby"
import Grid from "@material-ui/core/Grid"
import { standardPadding } from "../constants/Spacing"
import { launch88, jumpToSection} from "../hooks/routing"
import useTheme from "@material-ui/core/styles/useTheme"

const baseStyles = makeStyles(contentStyle);

const useStyles = makeStyles(theme => ({
  root:{
    backgroundSize: "cover",
    minHeight: "649px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "-webkit-font-smoothing": "antialiased",
    ...standardPadding.gatsbyImageSectionSpacing,
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
    },
  },
  container: {
    [theme.breakpoints.up("md")]:{
      //marginLeft: "47px"
    },
    [theme.breakpoints.down("sm")]:{
      marginLeft: 0,
      "& div:first-child": {
        paddingLeft: 0
      }
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    width: "100%"
  },
  outerContainer: {
    alignSelf: "center",
    marginBottom: "80px",
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
      lineHeight: "30px !important"
    }
  },
  //Grid item classes
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0 !important",
    }
  },
  leftTextMedium: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
    }
  },
  rightButtonMedium: {
    paddingLeft: theme.spacing(3),
    "@media (max-width: 404px)": {
      flexWrap: "wrap",
    },
  },
  responsiveButtons: {
    marginRight: 16,
    "@media (max-width: 352px)": {
     // width: "100%"
      //padding: `12px ${theme.spacing(1)}px`,
      marginLeft: "0 !important",
    },
  },
  marginZeroLarge: {
    [theme.breakpoints.up('md')]: {
      "& p": {
        marginBottom: 0,
      }
    }
  }
}));

const HeroPanel = ({widget: {title,
  standfirstText,
  image,
  primaryButtonLink,
  primaryButtonText,
  primaryAdvancedLinkSettings,
  primaryAdvancedAction,
  primaryAdvancedActionLink,
  secondaryButtonLink,
  secondaryButtonText,
  secondaryAdvancedActionLink,
  secondaryAdvancedLinkSettings,
  secondaryAdvancedAction,
  triggerChat
}}) => {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();
  const baseClasses = baseStyles();
  const bgImg = image && image.fluid || null;

  const allPageData = useAllPageData
  const primaryButton = () => {
      //build a regular link
    if(!primaryAdvancedLinkSettings && primaryButtonLink){
      return(
        <Link to={getRouteForLocatable(allPageData(), primaryButtonLink)} style={{textDecoration: "none"}}>
          <Button color="primary" className={classes.responsiveButtons} large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
            <Typography variant="button">
              {primaryButtonText}
            </Typography>
          </Button>
        </Link>
      )
    } else if (primaryAdvancedAction === "External Link" && detectExternalLink(primaryAdvancedActionLink)){
     return(
       <a href={primaryAdvancedActionLink} target="_blank" rel="noopener" style={{textDecoration: "none"}}>
         <Button color="primary" className={classes.responsiveButtons} large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
           <Typography variant="button">
             {primaryButtonText}
           </Typography>
         </Button>
       </a>
     )
    } else if (primaryAdvancedAction === "Phone Call") {
      return (
        <a href={`tel:${primaryAdvancedActionLink}`} rel="noopener" style={{textDecoration: "none"}}>
        <Button color="primary" className={classes.responsiveButtons} large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
          <Typography variant="button">
            {primaryButtonText}
          </Typography>
        </Button>
        </a>
      )
    } else if (primaryAdvancedAction === "Scroll To Section") {
      return(
        <Button onClick={() => {jumpToSection(primaryAdvancedActionLink, mobile)}} className={classes.responsiveButtons} color="primary" large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
          <Typography variant="button">
            {primaryButtonText}
          </Typography>
        </Button>
      )
    } else if (primaryAdvancedAction === "Live Chat") {
      return(
      <Button onClick={launch88} color="primary" className={classes.responsiveButtons} large styles={{height: "min-content", backgroundColor: "purple"}} classes={{root: classes.responsiveButtons}}>
        <Typography variant="button">
          {primaryButtonText}
        </Typography>
      </Button>
      )
    } else {
      return(
        <Button color="primary" large className={classes.responsiveButtons} styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}>
          <Typography variant="button">
            {primaryButtonText}
          </Typography>
        </Button>
      )
    }
  }

  const secondaryButton = () => {
    //build a regular link
    if(!secondaryAdvancedLinkSettings && secondaryButtonLink){
      return(
        <Link to={getRouteForLocatable(allPageData(), secondaryButtonLink)} style={{textDecoration: "none"}}>
          <Button color="clear" className={classes.responsiveButtons} large styles={{height: "min-content"}}  classes={{root: classes.responsiveButtons}}  >
            <Typography variant="button">
              {secondaryButtonText}
            </Typography>
          </Button>
        </Link>
      )
    } else if (secondaryAdvancedAction === "External Link" && detectExternalLink(secondaryAdvancedActionLink)){
      return(
        <a href={secondaryAdvancedActionLink} target="_blank" rel="noopener" style={{textDecoration: "none"}}>
          <Button color="clear" large className={classes.responsiveButtons} styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}  >
            <Typography variant="button">
              {secondaryButtonText}
            </Typography>
          </Button>
        </a>
      )
    } else if (secondaryAdvancedAction === "Phone Call") {
      return (
        <a href={`tel:${secondaryAdvancedActionLink}`} rel="noopener" style={{textDecoration: "none"}}>
          <Button color="clear" className={classes.responsiveButtons} large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}  >
            <Typography variant="button">
              {secondaryButtonText}
            </Typography>
          </Button>
        </a>
      )
    } else if (secondaryAdvancedAction === "Scroll To Section") {
      return(
        <Button onClick={() => {jumpToSection(secondaryAdvancedActionLink, mobile)}} className={classes.responsiveButtons} color="clear" large styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}  >
          <Typography variant="button">
            {secondaryButtonText}
          </Typography>
        </Button>
      )
    } else if (secondaryAdvancedAction === "Live Chat") {
      return(
        <Button onClick={launch88} color="clear" large className={classes.responsiveButton} styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}  >
          <Typography variant="button">
            {secondaryButtonText}
          </Typography>
        </Button>
      )
    } else {
      return(
        <Button color="clear" large className={classes.responsiveButtons} styles={{height: "min-content"}} classes={{root: classes.responsiveButtons}}  >
          <Typography variant="button">
            {secondaryButtonText}
          </Typography>
        </Button>
      )
    }
  }

  const noButtons = !primaryButtonText && !secondaryButtonText;

  return(
      <BackgroundImage
        Tag="div"
        className={clsx(classes.root, "inverted")}
        fluid={bgImg || null}
      >
      <div className={clsx(classes.outerContainer, baseClasses.container)} id="grid_container_container">
        <GridContainer className={classes.container} >
          <Grid item className={classes.leftTextMedium} justifyContent="center" sm={12} md={noButtons ? 12 : 7} >
              <Typography
                className={classes.title}
                variant="h1"
                style={{color: "white"}}
              >
                {title}
              </Typography>
              <div className={clsx("html__insert", "hero__block", "inverted", classes.marginZeroLarge)} dangerouslySetInnerHTML={{__html: standfirstText}} />
          </Grid >
          <Grid item className={clsx(classes.item, classes.rightButtonMedium)} justifyContent="flex-start" direction="column" sm={12} md={noButtons ? 0 : 5} >
            {
              primaryButtonText &&
                primaryButton()
            }
            {
              secondaryButtonText &&
                secondaryButton()
            }
          </Grid>
        </GridContainer>
      </div>
    </BackgroundImage>
  )
}
export default HeroPanel;
