/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button.jsx";
import style from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react/modalStyle.jsx";
import { CustomButton } from "./CustomButtons/CustomButton"
import { Typography } from "@material-ui/core"
import Colors from "../constants/Colors"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal({buttonText,
                                modalText,
                                title,
                                activate,
                                color,
                                buttonTextColor,
                                textModal}) {
  
const useStyles = makeStyles(style);

  const [liveDemo, setLiveDemo] = React.useState(false);
  const classes = useStyles();
  function handleClick (){
    setLiveDemo(!liveDemo);
  }

  return (
    <div style={{display: textModal ? "inline-block" : "block"}}>
      {
        !textModal &&
        <Button color={color || "primary"} large onClick={handleClick}>
          <Typography variant="button" style={{color: buttonTextColor || "white"}}>
            {buttonText}
          </Typography>
        </Button>
      }
      {
        textModal &&
          <a onClick={handleClick} style={{color: Colors.azure, cursor: "pointer"}}>
            {buttonText}
          </a>
      }

      <Dialog
        classes={{
          root: classes.modalRoot,
          scrollPaper: classes.scrollPaper,
          paper: classes.modal,
          container: classes.dialogContainer
        }}
        open={liveDemo}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setLiveDemo(false)}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            color="white"
            simple
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            onClick={() => setLiveDemo(false)}
          >
            {" "}
            <Close className={classes.modalClose} />
          </Button>
          <Typography variant="h4" className={classes.modalTitle}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <div className="html__insert" dangerouslySetInnerHTML={{__html: modalText}}/>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => setLiveDemo(false)} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}