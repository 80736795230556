import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import ContentBlock from "./content-block"
import HeroPanel from "./HeroPanel"
import ClinicSearch from "./widgets/ClinicSearch"
import ClinicMap from "./widgets/ClinicMap"
import WidgetTabCollection from "./widgets/TabCollection"
import WidgetContentContainer from "./widgets/ContentContainer"
import WidgetTeamLeadersList from "./widgets/TeamLeadersList"
import WidgetFullWidthImageText from "./widgets/FullWidthImageText"
import WidgetIconGrid from "./widgets/IconGrid"
import WidgetArticleList from "./widgets/ArticleList"
import Testimonials from "./Testimonials"
import CallbackRequestForm from "./forms/CallbackRequestForm"
import ContactUsForm from "./forms/ContactUsForm"
import SubmitAStoryForm from "./forms/SubmitAStoryForm"
import PatientReferralForm from "./forms/PatientReferralForm"
import HeaderSection from "./widgets/Header"
import WidgetProvidersList from "./widgets/ProvidersList"
import TextOnlyHeader from "./widgets/TextOnlyHeader"
import ImageTextList from "./widgets/ImageTextList"
import RequestAppointmentForm from "./forms/RequestAppointmentForm"
export default ({page, widgets, settings, location, carded}) => {
  if(!widgets) {return null};
  return widgets.map((widget, index) => {
    switch (widget && widget.__typename) {
      case "DatoCmsWidgetTabCollection":
        return <WidgetTabCollection key={index} widget={widget} location={location} />
      case "DatoCmsWidgetContentContainer":
        return <WidgetContentContainer key={index} widget={widget} location={location} />
      case "DatoCmsWidgetContentBlock":
        return <ContentBlock key={index} widget={widget} carded={carded}/>
      case "DatoCmsWidgetImageTextList":
        return <ImageTextList key={index} widget={widget} />
      case "DatoCmsWidgetHero":
        return <HeroPanel key={index} widget={widget} />
      case "DatoCmsWidgetClinicSearch":
        return <ClinicSearch key={index} widget={widget} />
      case "DatoCmsWidgetClinicMap":
        return <ClinicMap key={index} widget={widget} location={location} />
      case"DatoCmsWidgetStoriesSection":
        return <Testimonials key={index} widget={widget} settings={settings} />
      case "DatoCmsWidgetForm":
        switch (widget.template) {
          case "Callback request":
            return <CallbackRequestForm key={index} widget={widget} />
          case "Contact us":
            return <ContactUsForm key={index} widget={widget} />
          case "Story submission":
            return <SubmitAStoryForm key={index} widget={widget} />
          case "Patient referral":
            return <PatientReferralForm key={index} widget={widget} />
          case "Appointment request":
            return <RequestAppointmentForm key={index} widget={widget} />
          default:
            return <CallbackRequestForm key={index} widget={widget} />
        }
      case "DatoCmsWidgetTeamLeadersList":
        return <WidgetTeamLeadersList key={index} widget={widget} />
      case "DatoCmsWidgetFullWidthImageText":
        return <WidgetFullWidthImageText key={index} widget={widget} />
      case "DatoCmsWidgetIconGrid":
        return <WidgetIconGrid key={index} widget={widget} />
      case "DatoCmsWidgetHeader":
        return <HeaderSection key={index} widget={widget} />
      case "DatoCmsWidgetArticleList":
        return <WidgetArticleList key={index} page={page} widget={widget} location={location} />
      case "DatoCmsWidgetProvidersList":
        return <WidgetProvidersList key={index} page={page} widget={widget} />
      case "DatoCmsWidgetTextOnlyHeader":
        return <TextOnlyHeader key={index} widget={widget} />
      default:
        return <p key={index}>Sorry, {widget && widget.__typename} is not a widget yet</p>
    }
  })
}
