import React, { useRef, useState } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"
import styles from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react/customSelectStyle.jsx";

import { Link, navigate } from 'gatsby';

import { Typography } from "@material-ui/core"
import Colors from "../../constants/Colors"
import StateSelect from "../Selects/StateSelect"

import clsx from "clsx"

import idealSvgPin from "../../assets/img/map-pin-graphic1.svg"
import CurrentLocationFinder from "../CurrentLocationFinder"

import {standardPadding} from "../../constants/Spacing"
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import FormControl from "@material-ui/core/FormControl"
import BackgroundImage from "gatsby-background-image"

import axios from "axios";
import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button"
import "../../constants/html_block_styles.scss"

const timStyles = makeStyles(styles);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundSize: "cover",
    "-webkit-font-smoothing": "antialiased",
  },
  title: {
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    }
  },
  overlay: {
    backgroundColor: `rgba(78,100,137, 0.5)`,
  },
  container: {
    ...container,
    paddingTop: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    flexGrow: 0,
    alignItems: "center",
    [theme.breakpoints.down('sm')]:{
      ...standardPadding.mobile,
      paddingTop: 150,
    },
    [theme.breakpoints.up('md')]:{
      ...standardPadding.desktop,
      paddingTop: 150,
    },
  },
  select: {
    border: "2px solid white",
    borderRadius: "2px",
    minWidth: "300px",
    textTransform: "none",
    //native styles to apply if iOS
    color: props => props.isNative ? "white" : null,
    fontWeight: props => props.isNative ? "600" : null,
    padding: props => props.isNative ? "12px 0 10px 7px" : null,
    fontFamily: props => props.isNative ? "Lato, Verdana, Arial, sans-serif" : null,
    fontSize: 14,
    //Native Select styling for Clinic Search dropdown
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      color: "white",
      fontWeight: "600",
      padding: "12px 0 10px 7px",
      fontFamily: "Lato, Verdana, Arial, sans-serif",
    }
  },
  selectMenu: {  //select menu styling
    paddingTop: 10,
    "svg": {
      color: Colors.white
    },
    "& > div": {
      backgroundColor: Colors.tundora,
    },
    "& > div > ul": {
      backgroundColor: Colors.white,
    },

  },
  selectPrimary: {
    paddingLeft: 10,
    "& svg": {
      transform: `translateX(-6px)`,
    }
  },
  bodyCopy: {
    marginBottom: "1rem",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  input: {
    color: "white",
  },
  inputDiv: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "76%",
    }
  },
  selectMenuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]:{
      flexWrap: "wrap",
      justifyContent: "space-between",
      "svg": {
        fill: Colors.white,
      },
      "& input": {
        width: "75%",
        marginLeft: 0,
        [theme.breakpoints.down('sm')]: {
          width: "100%",
        }
      },
      "& button": {
        width: "22%",
      },
    },
    "& div:first-child": {
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
      width: "100%",
    },
    "& button": {
      marginLeft: "10px",
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
      }
    },
  },
  selectZip: {
    borderRadius: 2,
    maxWidth: "100%",
    marginLeft: 20,
    paddingLeft: 10,
    height: 43,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    color: Colors.inverted,
    border: "2px solid white",
    backgroundColor: "transparent",
    "&::placeholder": {
      opacity: 1,
      color: Colors.inverted,
      fontSize: "14px",
      fontWeight: "bold",
      letterSpacing: "0.0075em",
    }
  },
  selectMenuItem: {
    color: Colors.azure,
    textTransform: "none",
    paddingLeft: 14,
    marginLeft: 0,
    marginRight: 0,
    backgroundColor: Colors.white,
    "&:hover": {
      boxShadow: "none",
      //backgroundColor: Colors.grayChateau,
      backgroundColor: "rgba(0, 0, 0, 0.14)",
    }
  },
  icon: {
    width: 44,
    height: 44,
    backgroundColor: Colors.white,
    'svg': {
      color: Colors.azure,
    },
  },
  innerIcon: {
    color: Colors.white,
  },
  currentIcon: {
   marginBottom: "1.5rem",
  },
  locationBox: {
    marginTop: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  menuTypography: {
    marginLeft: 10,
  },
  goButton: {
    paddingTop: 12,
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
    }
  },
  error: {
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    color: "red",
    fontSize: 12,
    height: 12,
    marginBottom: -12,
    paddingLeft: 0,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    }
  },
}));


export default function({ widget }){
  const bgImg = !!(widget && widget.backgroundImage && widget.backgroundImage.fluid && widget.backgroundImage.fluid.src) ? widget.backgroundImage.fluid.src : null;
  const classes = timStyles();
  const iOS = typeof(navigator) !== "undefined" && typeof(window) !== "undefined" ? /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream : false;
  const customClasses = useStyles({bgImg: bgImg, isNative: iOS});
  const [selectedState, setSelectedState] = useState('none');
  const [currentPositionPoint, setCurrentPositionPoint] = React.useState(null);
  const [zipCode, setZipCode] = React.useState(null);
  const [zipError, setZipError] = React.useState(null);
  const locatorRef = useRef(null);


  if (!!currentPositionPoint || selectedState !== 'none') {
    console.log("navigating", currentPositionPoint)
    navigate(
        "/suboxone-clinics",
        {
          state: { selectedState, currentPositionPoint },
        }
      )
  }

  const handleGoClick = () => {
    axios
      .get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + zipCode + '.json?types=postcode&country=US&access_token=' + process.env.GATSBY_MAPBOX_ACCESS_TOKEN)
      .then(response => {
        if(zipError !== null){
          setZipError(null);
        }
        console.log("response.data.features[0].geometry", response.data.features[0].geometry)
        setCurrentPositionPoint(response.data.features[0].geometry)
      })
      .catch(error => {
        setZipError("Please enter a valid zip code.")
        console.log(error)
      })
  }


  return(
    <BackgroundImage className={customClasses.root}
      fluid={widget && widget.backgroundImage && widget.backgroundImage.fluid}
    >
      <div className={customClasses.overlay}>
        <div className={customClasses.container}>
        <img src={idealSvgPin} alt="find-a-clinic pin" />
        <Typography variant="h1" className={customClasses.title} style={{color: Colors.inverted, marginBottom: "1rem"}} >{widget.title}</Typography>
          <div className={clsx("html__insert",  "body1", "inverted", customClasses.bodyCopy)} dangerouslySetInnerHTML={{__html: widget.body}}></div>
        <FormControl>
        <div className={clsx(customClasses.selectMenuContainer, "svg-override-white")}>
          <StateSelect
            iconColor={Colors.white}
            value={selectedState}
            updateValue={setSelectedState}
            defaultColor={Colors.white}
            rootStyle={{flexGrow: 1}}
            className="input-padding-override"
            selectMenuProps={{
              className: clsx(classes.selectMenu, customClasses.selectMenu),
              style: {paddingTop: 10}
            }}
            selectClasses={{
              select: clsx(classes.select, customClasses.select, customClasses.selectPrimary)
            }}
            menuItemClasses={{
              root: clsx(classes.selectMenuItem, customClasses.selectMenuItem),
              input: customClasses.input
            }}
            classes={{
              menuTypography: classes.menuTypography,
            }}
            iOS={iOS}
          />
          <div className={customClasses.inputDiv}>
            <input onChange={event => setZipCode(event.target.value)}
              className={customClasses.selectZip}
               type="number"
              placeholder="Enter your zip"
              />
              {
                zipError &&
                  <p className={customClasses.error}>{zipError}</p>
              }
          </div>
          <Button className={customClasses.goButton} color="primary" style={{marginTop: 6}} large onClick={handleGoClick}>
            <Typography variant="button">Go</Typography>
          </Button>
        </div>
        <div className={clsx(customClasses.locationBox, customClasses.currentIcon, "location-finder-override-azure")}>
          <CurrentLocationFinder
            classes={{
              icon: clsx(classes.icon, customClasses.icon),
              innerIcon: classes.innerIcon,
            }}
            onStateSelected={setSelectedState}
            onGetCurrentPosition={setCurrentPositionPoint}
            ref={locatorRef}
            label={
              <div>
                <Typography variant="h3" style={{
                  fontSize: 14,
                  color: Colors.inverted,
                  marginLeft: "10px",
                  width: "fit-content",
                  fontWeight: 700,
                  marginBottom: 0
                }}>Use my location</Typography>
              </div>
            }
          />
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "64px"
        }}>
          <Link to="/suboxone-clinics" style={{textDecoration: "none"}}>
            <Button color="clear"  transparent>
              <Typography variant="button">
                View all clinics
              </Typography>
            </Button>
          </Link>
        </div>
        </FormControl>
        </div>
      </div>
    </BackgroundImage>
  )
}
