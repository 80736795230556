import React, { useEffect, useRef, useState } from "react"
import {Link} from "gatsby"
import ListItem from "@material-ui/core/ListItem"
import List from "@material-ui/core/List"
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import Header from "../vendor/mui-pro/src/components/Header/Header"
import headerLinksStyle from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react/components/headerLinksStyle"
import { createStyles } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import clsx from 'clsx';
import NavigationNode from "../components/navigation-node"
import Typography from "@material-ui/core/Typography"
import Colors from "../constants/Colors"
import useWindowScroll from "../hooks/useWindowScroll"

const baseStyles = makeStyles(headerLinksStyle);
const uniqueStyles = makeStyles({
  root: {
    borderRadius: 0,
  },
  button: {
    color: Colors.white,
    textDecoration: "none",
  },
  searchIcon: {
    fontSize: 24,
    transform: "translateY(-3px)",
  },
  displayNone: {
    display: "none"
  },
  linksContainer: {
    //paddingRight: 0,
  },
});


const listStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: props => props.drawerOpen ? "column" : "row",
    }
  },
  logoContainer: {
    maxHeight: 48
  },
  logoNoThrash: {
    maxHeight: 65,
  },
}));

function NavHeader({widgetLinks, color, listItemStyling, logo, scrollLogo, secondary, heightHack, smallBreakpoint}){
  const classes = baseStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isRendered, setRender] = useState(false);

  const smallDown = smallBreakpoint;
  const listClasses = listStyles({drawerOpen: drawerOpen});
  const scroll = useWindowScroll();
  const preScrolled = scroll >= 300; //Used to UX thrashing for image elements
  const scrolled = scroll >= 350;
  const listItemClasses = uniqueStyles()

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    if (!isRendered){
      setRender(true);
    }
  })

  return(
    <Header
      key={scrolled}
      logo={logo}
      disableGutters
      displayDrawer={smallDown}
      offset={ !scrolled }
      secondary={scrolled ? true : secondary }
      mobileOpen={drawerOpen}
      searchButtonOnClick={handleDrawerToggle}
      containerStyle={listItemClasses.logoContainer}
      color={ preScrolled ? Colors.white : color}
      sticky={ scrolled }
      heightHack={ heightHack }
      classes={{
        customDrawerStyles: classes.customDrawerStyles,
        displayNone: classes.displayNone,
        container: listItemClasses.linksContainer,
      }}
      brand={
        <Link to="/" activeClassName="header-ripple">
          {
            !preScrolled &&
              <img className={clsx(drawerOpen ? listClasses.logoNoThrash : null)} src={logo ? logo.url : null} alt="foo" />
          }
          {
            preScrolled &&
            <img className={clsx(drawerOpen ? listClasses.logoNoThrash : null)} src={scrollLogo ? scrollLogo.url : null} alt="foo" />
          }
        </Link>
      }
      links={
        <List component={"div"}
          disablePadding={true}
              className={listClasses.root}
        >
          {
            widgetLinks &&
            widgetLinks.map((L) => {
              return(
              <ListItem className={clsx(classes.listItem, listItemClasses.listItem)} button={true}>
                {
                  L.link &&
                  <NavigationNode
                    classes={{
                      navStyle: listItemClasses.button
                    }}
                    datoItem={L.link}
                  >
                    <Button
                      color={"transparent"}
                      style={scrolled ? {color: Colors.kashmirBlue} : listItemStyling}
                      target="_blank"
                      rel="noopener"
                      className={clsx(classes.navButton,listItemClasses.button)}
                    ><Typography variant="button">{L.label}</Typography>
                    </Button>
                  </NavigationNode>
                }
                {
                  L.externalLink !== "" &&
                    <Link href={L.externalLink}>
                      <Button
                        color={"transparent"}
                        style={listItemStyling}
                        target="_blank"
                        rel="noopener"
                        className={classes.navButton}
                      >{L.label}
                      </Button>
                    </Link>
                }
              </ListItem>
              )
            })

          }
        </List>
      }
    />
    )
}


export default NavHeader;
