import React from 'react'
import BackgroundImage from "gatsby-background-image"
import { Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles/createStyles"
import Colors from "../../constants/Colors"
import clsx from "clsx"
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"

const useStyles = makeStyles((theme) => createStyles({
  container: {
    //minHeight: 300,
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    //maxWidth: "60%",
    margin: "auto",
    textAlign: "center",
   // paddingTop: props => props.headerSize === "big" ? "30px" : "10px",
   // paddingBottom: props => props.headerSize === "big" ? "30px" : "10px",
    "& h1": {
      color: Colors.inverted,
      marginBottom: 16,
      textAlign: "center",
    },
    "& h6": {
      color: Colors.inverted,
    }
  },
  innerContainer: {
    maxWidth: 680,
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    //marginBottom: theme.typography.h6.marginBottom
    marginBottom: 20, //override requested by designer
  },
  noIntroText: {
    paddingTop: 64,
    paddingBottom: 44,
    minHeight: 0,
  },
  introText: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  background: {
    "-webkit-font-smoothing": "antialiased",
  },
  width_100: {
    //maxWidth: "1100px",
    ...container,
  },
  leftAlign: {
    textAlign: "left !important"
  },
  centerAlign: {
    textAlign: "center"
  }
}));

export default function HeaderSecton({widget}){
  const classes = useStyles(widget);
  const backgroundImage = widget.backgroundImage && widget.backgroundImage.fluid
  if (backgroundImage) {
    /*=========
    HACK: Until we decide to add in an "anchor" field, we're handling client requests for additional
    anchors through hard-coding.
     ========*/
    const addAnchor = widget.id === "DatoCmsWidgetHeader-7392007-en"
    return(
      <BackgroundImage
        tag="div"
        className={classes.background}
        fluid={backgroundImage}
        fadeIn
        critical
      >
          <div
            className={clsx(classes.container, !widget.text ? classes.noIntroText : classes.introText,
            )
          }>
            <span id={addAnchor ? "SatelliteServices" : null}  style={{visibility: "hidden", position: "absolute", top: -60}}></span>
            <div className={clsx(classes.innerContainer, widget.maxWidth === "100%" ? classes.width_100 : null,
              widget.alignment === "Left" ? classes.leftAlign : classes.centerAlign)}>
              <Typography className="inverted-header-override" variant={widget.headerSize === "Large" ? "h1" : "h2"} >{widget.title}</Typography>
              <Typography variant="subtitle1" >{widget.text}</Typography>
            </div>
          </div>
      </BackgroundImage>
    )
  } else {
    return (
      <Typography variant="h1">{widget.title}</Typography>
    )
  }
}
