import React, {useState} from 'react';
import BaseForm from "./BaseForm"
import FormControl from "@material-ui/core/FormControl"
import { ErrorMessage, Field } from "formik"
import Button from "@material-ui/core/Button"
import { makeStyles, Typography } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import { fromJS } from "immutable"
import Grid from "@material-ui/core/Grid"
import { isValid, getYear } from "date-fns";
import { mainStyles, gridItemStyles, buttonStyles } from "../../constants/formStyles";
import {Link} from "gatsby"
import Modal from "../Modal"
import Colors from "../../constants/Colors"


export default ({widget}) => {
  const classes = mainStyles();
  const itemClasses = gridItemStyles();
  const responsiveButtonClasses = buttonStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const stateData = useStaticQuery(graphql`
      query ClinicStatesFormsQuery {
          allDatoCmsClinic {
              edges {
                  node {
                      state
                  }
              }
          }
      }
  `)

  const stateNames = fromJS(stateData.allDatoCmsClinic.edges.flatMap(e => e.node)).map(f => f.get('state')).toSet().toList().sort();
  const appointmentDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const appointmentTimes = ["Early Morning", "Midday", "Early Afternoon", "Late Afternoon"];

  const renderModal = (modalText, buttonText) => {
    return(
      <Modal
        open={modalOpen}
        buttonTextColor={Colors.azure}
        modalText={modalText}
        color={"white"}
        onClose={() => setModalOpen(false)}
        aria-labelledby="Treatment and Financial Responsibility statement"
        buttonText={buttonText}
        textModal
      />
    )
  }

  const textForModal = () => {
    const modalText = "By clicking the \"I AGREE\" checkbox, you also indicate that you accept financial responsibility, " +
      "if any, for the Ideal Option services you receive. We may verify your insurance benefits and may submit a claim to" +
      " your health insurer for payment. Without waiving your financial responsibility, you agree to assign your right to " +
      "medical benefits to Ideal Option. If your health insurer does not pay your claim, or only pays a portion of your " +
      "claim, you agree to pay the remaining charges. I authorize Ideal Option, PLLC to disclose to any third-party payor " +
      "all necessary information and relevant portions of my patient record for the purpose of receiving payment for services " +
      "rendered."
    const modalLinkText = "treatment and financial responsibility"
    return(
      renderModal(modalText, modalLinkText)
    )
  }

  return(
    <BaseForm widget={widget} className={classes.root} classes={classes}
      validation={values => {
        let errors = {};

        if(!values.firstName){
          errors.firstName = 'Required';
        }

        if(!values.lastName){
          errors.lastName = 'Required';
        }

        if((!values.state) || (values.state === "Select a State")){
          errors.state = 'Required';
        }

        if(!values.zipCode){
          errors.zipCode = 'Required';
        }

        if(!values.phoneNumber){
          errors.phoneNumber = 'Required';
        }

        if(!values.appointmentType){
          debugger;
          errors.appointmentType = 'Required';
        }

        if (values.email) {
          if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
        }

        if((!values.dateOfBirth) || (!isValid(new Date(values.dateOfBirth)) ||
          getYear(new Date(values.dateOfBirth)) < 1900)){
            errors.dateOfBirth = 'Please enter a valid date of birth';
        }

        if(!values.insuranceProvider){
          errors.insuranceProvider = 'Required';
        }

        if((!values.preferredDay) || (values.preferredDay === "Select a Day")){
          errors.preferredDay = 'Required';
        }

        if((!values.preferredTime) || (values.preferredTime === "Select a Time Range")){
          errors.preferredTime = 'Required';
        }

        return errors;
      }}
    >
      {({ isSubmitting, submitForm, status, errors}) => (
        <div className={classes.container}>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>First Name*</label>
              <Field name="firstName"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             className={errors && errors.firstName ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="firstName"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Last Name*</label>
              <Field name="lastName"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             className={errors && errors.lastName ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="lastName"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>State*</label>
              <Field name="state"
                     render={
                       ({field}) => {
                         return (
                           <select
                             {...field}
                             className={errors && errors.state ? classes.errorInput : classes.customInput}
                           >
                             <option value="Select a State">Select a State</option>
                             {
                               stateNames && stateNames.map(s => {
                                 return(
                                   <option value={s}>{s}</option>
                                 )
                               })
                             }
                           </select>
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="state"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>ZIP Code*</label>
              <Field name="zipCode"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             className={errors && errors.zipCode ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="zipCode"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Phone Number*</label>
              <Field name="phoneNumber"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             className={errors && errors.phoneNumber ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="phoneNumber"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Email*</label>
              <Field name="email"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             className={errors && errors.email ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="email"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Date of Birth*</label>
              <Field name="dateOfBirth"
                     render={
                       ({field}) => {
                         return (
                           <input
                             type="date"
                             {...field}
                             className={errors && errors.dateOfBirth ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="dateOfBirth"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Insurance Provider*</label>
              <Field name="insuranceProvider"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             placeholder={`If you don't have insurance, please enter "self-pay"`}
                             className={errors && errors.insuranceProvider ? classes.errorInput : classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="insuranceProvider"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Social Security Number</label>
              <Field name="socialSecurityNumber"
                     render={
                       ({field}) => {
                         return (
                           <input
                             {...field}
                             placeholder="Please enter your SSN so we can verify your insurance."
                             className={classes.customInput}
                           />
                         )
                       }
                     }>
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Appointment Type</label>
              <Field name="appointmentType"
                     render={
                       ({field}) => {
                         return (
                           <React.Fragment>
                             <div style={{display: "flex"}}>
                               <input type="radio" {...field} id="virtual" value="virtual"
                                      name="appointmentType" style={{marginRight: 8}}
                               />
                               <label htmlFor="virtual">Virtual Visit (via video chat)</label>
                             </div>
                             <div style={{display: "flex"}}>
                               <input type="radio" {...field} id="clinic" value="clinic"
                                      name="appointmentType" style={{marginRight: 8}}
                               />
                               <label htmlFor="clinic">Clinic Visit</label>
                             </div>
                             <div style={{display: "flex"}}>
                               <input type="radio" {...field} id="none" value="no preference"
                                      name="appointmentType" style={{marginRight: 8}}
                               />
                               <label htmlFor="no preference">No Preference / Unsure</label>
                             </div>
                           </React.Fragment>
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="appointmentType"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Preferred Day for Appointment*</label>
              <Field name="preferredDay"
                     render={
                       ({field}) => {
                         return (
                           <select
                             {...field}
                             className={errors && errors.preferredDay ? classes.errorInput : classes.customInput}
                           >
                             <option value="Select a Day">Select a Day</option>
                             {
                               appointmentDays && appointmentDays.map(d => {
                                 return(
                                   <option value={d}>{d}</option>
                                 )
                               })
                             }
                           </select>
                         )
                       }
                     }>
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Preferred Time for Appointment*</label>
              <Field name="preferredTime"
                     render={
                       ({field}) => {
                         return (
                           <select
                             {...field}
                             className={errors && errors.preferredTime ? classes.errorInput : classes.customInput}
                           >
                             <option value="Select a Time Range">Select a Time Range</option>
                             {
                               appointmentTimes && appointmentTimes.map(t => {
                                 return(
                                   <option value={t}>{t}</option>
                                 )
                               })
                             }
                           </select>
                         )
                       }
                     }>
              </Field>
              <div className={classes.error}><ErrorMessage name="preferredTime"></ErrorMessage></div>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Additional Comments</label>
              <Field name="additionalComments"
                     render={
                       ({field}) => {
                         return (
                           <textarea
                             {...field}
                             rows="4"
                             className={classes.customInput}
                             style={{height: "auto"}}
                           />
                         )
                       }
                     }>
              </Field>
            </FormControl>
          </Grid>
          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <Field name="billingPermission"
                     render={
                       ({field}) => {
                         return (
                           <div style={{display: "flex", alignItems: "center"}}>
                             <input
                               type="checkbox"
                               {...field}
                               name="billingPermission"
                               style={{marginRight: 8}}
                             />
                           <label>By checking this box, I consent to {textForModal()} and acknowledge receipt of <Link style={{color: Colors.azure, textDecoration: "none"}}
                              to="/notice-of-privacy-practices">Notice of Privacy Practices.</Link>
                             </label>
                           </div>
                         )
                       }
                     }>
              </Field>
            </FormControl>
          </Grid>
          <Button onClick={submitForm} className={responsiveButtonClasses.root} disabled={isSubmitting}>
            <Typography variant="button">
              Request Appointment
            </Typography>
          </Button>
        </div>
      )}
    </BaseForm>

  )
}