import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby"
import CircleProfileCard from "../CircleProfileCard"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/styles"
import { Typography } from "@material-ui/core"
import Colors from "../../constants/Colors"
import Grid from "@material-ui/core/Grid"
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import { standardPadding } from "../../constants/Spacing"

const useStyles = makeStyles((theme) => createStyles({

  root: {
    padding: 0,
    margin: 0,
    backgroundColor: Colors.mystic,
    ...standardPadding.standardWidgetBottomPadding,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: 0,
    },
    "& > div": {
      paddingTop: props => props.overlap ? 0 : 64,
    }
  },
  sectionContainer: {
    ...container,
    backgroundColor: Colors.pickledBluewood,
    borderRadius: 5,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    transform: props => props.overlap ? "translateY(-34px)" : 0,
    marginBottom: props => props.overlap ? -34 : 0,
  },
  imageContainer: {
    width: "150px",
    margin: "auto",
    height: "150px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  gridItem: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
      marginBottom: "50px"
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: 26,
    },
  },
  teamCardImageContainer: {
    width: "fit-content",
    margin: "auto",
    height: "150px",
    borderRadius: "50%",
    overflow: "hidden",
  },
  teamCardImage: {
    width: "150px !important",
    height: "150px",
  },
  teamCardGrid: {
    borderRadius: 5,
    paddingBottom: "32px",
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
      paddingTop: "64px",
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: "32px",
      "& > div:last-of-type": {
        marginBottom: 0,
      }
    }
  },
  providerName: {
    fontFamily: "Lato, Verdana, Arial, sans-serif",
  }
}))


export default ({widget}) => {
  const classes = useStyles({overlap: widget.overlap});
  const data = useStaticQuery(graphql`
    query ProvidersQuery {
      allDatoCmsTeamMember(filter: {providerDisplay: {eq: true}}) {
        edges {
          node {
            ...teamMembers
          }
        }
      }
    }
  `)

  const _mappedTeamMembers = data.allDatoCmsTeamMember.edges.flatMap(e => e.node);
  const mappedTeamMembers = _mappedTeamMembers.reverse();

  return (

  <div className={classes.root} >
    <div>
      <div className={classes.sectionContainer}>
        {
          mappedTeamMembers &&
            <Grid container spacing={2} justifyContent="center" className={classes.teamCardGrid}>
              {
                mappedTeamMembers.map(function(m) {
                  return (
                    <Grid item xs="12" md="4" className={classes.gridItem}>
                      <CircleProfileCard
                        italicBody
                        name={m.name}
                        jobTitle={m.jobTitle}
                        description={m.description}
                        image={m.photo}
                        profileCardClasses={{
                          imageContainer: classes.teamCardImageContainer,
                          image: classes.teamCardImage,
                          providerName: classes.providerName,
                        }}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
        }
      </div>
    </div>
  </div>
  )
}
