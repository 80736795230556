import React from 'react';
import { Form, Field, ErrorMessage } from 'formik';
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles, Typography } from "@material-ui/core"
import Colors from "../../constants/Colors"
import Button from "@material-ui/core/Button"
import createStyles from "@material-ui/styles/createStyles/createStyles"
import BaseForm from "./BaseForm"
import { formStyles } from "../../constants/cssMixins"

const gridItemStyles =  makeStyles((theme) => createStyles({
  root: {
    marginBottom: 24,
  },
}));

const responsiveFormStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
    border: "2px solid #4E6489",
    backgroundColor: Colors.mystic,
    height: "44px",
  },
  inputFields: {
    height: "100%"
  }
}));

const buttonStyles = makeStyles((theme) => createStyles({
  root: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 1,
    },
    [theme.breakpoints.up('sm')]: {
      paddingRight: "30px",
      paddingLeft: "30px",
      flexGrow: 0,
      marginLeft: "5px",
      marginRight: "5px",
    },
    backgroundColor: Colors.tulipTree,
    color: Colors.white,
    paddingTop: "12px",
    paddingBottom: "12px",
    marginTop: "20px",
    marginLeft: "5px",
    marginRight: "5px"
  }
}));

const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: Colors.catskillWhite,
    paddingBottom: 69,
    paddingTop: 50,
  },
  title: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    "& h1": {
      color: Colors.inverted,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    maxWidth: 700,
    margin: "auto",
    backgroundColor: Colors.white,
    padding: "34px 40px",
    borderRadius: 5,
    [theme.breakpoints.down('sm')]:{
      width: "75%",
    },
    [theme.breakpoints.down('xs')]:{
      width: "95%",
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  label: {
    color: Colors.kashmirBlue,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
    fontSize: 14,
  },
  input:{
    backgroundColor: Colors.white,
    border: `1px solid red`,
    borderRadius: 6,
  },
  outlined: {
    border: `25px solid red`,
  },
  focused:{
    border: `1px solid red`,
  },
  customInput: {
    ...formStyles.customInput,
    paddingLeft: 10,
    "&::placeholder": {
    },
    "&::active": {
      paddingLeft: 10,
    }
  },
  error: {
   color: "red",
   fontSize: 12,
  },
  errorInput: {
    ...formStyles.errorInput,
    paddingLeft: 10,
  },
  success: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    margin: "auto",
    padding: "34px 40px",
    backgroundColor: Colors.white,
    borderRadius: 6,
    //textAlign: "center",
    marginTop: 50,
    marginBottom: 69,
    "& p": {
    },
  },
  helpText:{
    "&:placeholder": {

    }
  }
}));


const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default ({ widget }) => {
  const classes = useStyles();
  const itemClasses = gridItemStyles();
  const formClasses = responsiveFormStyles();
  const responsiveButtonClasses = buttonStyles();

  return (
    <BaseForm widget={widget} className={classes.root} classes={classes}
    validation={values => {
      let errors = {};

      if (!values.patientName) {
        errors.patientName = 'Required';
      }

      if (!values.patientDateOfBirth) {
        errors.patientDateOfBirth = 'Required';
      }

      if (!values.phone) {
        errors.phone = 'Required';
      }

      if (!values.email) {
        errors.email = 'Required';
      }

      if (values.email) {
        if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
      }

      console.log(errors);

      return errors;
    }}>
      {({ isSubmitting, submitForm, status, errors}) => (
        <div className={classes.container}>
          <Grid item xs={12} classes={{root: itemClasses.root}} >
            <FormControl fullWidth={true}>
              <label className={classes.label}>Patient Name*</label>
              <Field name="patientName"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={errors && errors.patientName ? classes.errorInput : classes.customInput}
                      placeholder={"First and Last"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="patientName"></ErrorMessage></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}} >
            <FormControl fullWidth={true}>
              <label className={classes.label}>Patient Date of Birth*</label>
              <Field name="patientDateOfBirth"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={errors && errors.patientDateOfBirth ? classes.errorInput : classes.customInput}
                      placeholder={"Ex. 03/05/1982"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="patientDateOfBirth"></ErrorMessage></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}} >
            <FormControl fullWidth={true}>
              <label className={classes.label}>Contact Phone Number*</label>
              <Field name="phone"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={errors && errors.phone ? classes.errorInput : classes.customInput}
                      placeholder={"Yours or the patient's"}
                    />
                  )
                }
              }>
              </Field>
              <div className={classes.error}><ErrorMessage name="phone"></ErrorMessage></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Contact Email*</label>
              <Field  name="email" type="email"
                     render= {
                       ({field}) => {
                         return(
                           <input
                             {...field}
                             className={errors && errors.email ? classes.errorInput : classes.customInput}
                             placeholder={"Yours or the patient's"}
                           />
                         )
                       }
                     }
              >
              </Field>
              <div className={classes.error}><ErrorMessage name="email"></ErrorMessage></div>
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}} >
            <FormControl fullWidth={true}>
              <label className={classes.label}>Your Name</label>
              <Field name="name"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"First and Last"}
                    />
                  )
                }
              }>
              </Field>
              <ErrorMessage name="name" />
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Your Organization</label>
              <Field name="organization"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"ex: Alaska Regional Hospital"}
                    />
                  )
                }
              }>
              </Field>
              <ErrorMessage name="organization" />
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Patient Insurance Provider</label>
              <Field name="insuranceProvider"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"ex: Medicaid of Alaska"}
                    />
                  )
                }
              }>
              </Field>
              <ErrorMessage name="insuranceProvider" />
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>City & State</label>
              <Field name="cityAndState"
                render= {
                ({field}) => {
                  return(
                    <input
                      {...field}
                      className={classes.customInput}
                      placeholder={"ex: Anchorage, AK"}
                    />
                  )
                }
              }>
              </Field>
              <ErrorMessage name="cityAndState" />
            </FormControl>
          </Grid>

          <Grid item xs={12} classes={{root: itemClasses.root}}>
            <FormControl fullWidth={true}>
              <label className={classes.label}>Additional Information</label>
              <Field name="additionalInformation"
                render={
                  ({ field }) => {
                    return (
                      <textarea
                        rows="4"
                        {...field}
                        className={classes.customInput}
                        style={{height: "auto"}}
                        placeholder={"Please include any additional information you think will be helpful about the patient."}
                      />
                    )
                  }
                }>
              </Field>
              <ErrorMessage name="additionalInformation" />
            </FormControl>
          </Grid>

          <Button onClick={submitForm} className={responsiveButtonClasses.root} disabled={isSubmitting}>
            <Typography variant="button">
             Send
            </Typography>
          </Button>
        </div>
      )}
    </BaseForm>
  )
}
