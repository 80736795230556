import React, { useState } from "react";
import { Typography } from "@material-ui/core"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Colors from "../../constants/Colors"
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import clsx from "clsx"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: Colors.mystic,
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),

  },
  noIntroTextRoot: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  noIntroText: {
    paddingTop: 64,
    paddingBottom: 64,
    minHeight: 0,
  },
  introText: {
    paddingTop: 10,
  },
  container: {
   ...container,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  item :{
    margin: "auto",
    //width: "75%",
    [theme.breakpoints.up('sm')]: {
      maxWidth: props => props.maxWidth === "40%" ? 680 : "100%"
    },
    textAlign: props => props.alignment === "Left" ? "left" : "center",
  },
  text: {
    ...theme.typography.subtitle1,
  },
  leftAlign: {
    textAlign: "left"
  },
  centerAlign: {
    textAlign: "center"
  },
  color: Colors.inverted,
}))

export default ({widget: {text, title, headerSize, maxWidth, alignment}}) => {
  const classes = useStyles({maxWidth: maxWidth, alignment: alignment});

  return(
    <div className={clsx(classes.root, text ? null : classes.noIntroTextRoot)}>
      <div className={classes.container}>
      <div className={clsx(classes.item, classes.marginDown, text ? classes.introText : classes.noIntroText)}>
        <Typography style={{marginBottom: 0}} variant={headerSize === "Large" ? "h1" : "h2"}>
          {title}
        </Typography>
      </div>
      <div className={classes.item}>
        <div className={classes.text} dangerouslySetInnerHTML={{__html: text}}></div>
      </div>
      </div>
    </div>
  )
}