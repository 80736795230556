import React from "react"

import Grid from "@material-ui/core/Grid/index"
import { Typography } from "@material-ui/core"

import Img from "gatsby-image"
import Accordion from "../../vendor/mui-pro/src/components/Accordion/Accordion"
import makeStyles from "@material-ui/core/styles/makeStyles"
import { createStyles } from "@material-ui/styles"
import Colors from "../../constants/Colors"
import { grayColor } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import clsx from "clsx"
import { paddingAdjustments, standardPadding } from "../../constants/Spacing"
import {standardMargins} from "../../constants/Spacing"

const useStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: "transparent",
    minHeight: 200,
    paddingTop: 30,
    paddingBottom: 30,
  },
  accordionRoot: {
    "& > div": {
      backgroundColor: "transparent"
    },
    "& [class*=\"MuiIconButton-root\"]": {
      top: "-4px !important"
    }
  },
  container: {
    ...standardPadding.standardWidgetBottom
  },
  containerLarge: {
      ...standardMargins.bottom.contentInclusive
  },
  imageGridItem: {
    [theme.breakpoints.up('md')]: {
      justifyContent: "flex-end",
      display: "flex",
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      display: "flex",
    }
  },
  containerWidth: {
    paddingLeft: 13,
    paddingRight: 13
  },
  expansionPanelSummary: {
    minHeight: "auto !important",
    backgroundColor: "transparent",
    borderBottom: "1px solid " + grayColor[6],
    padding: "10px 10px 5px 0px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    //color: grayColor[1]
    color: Colors.azure
  },
  ExpansionPanelSummaryExpanded:{
    color: `${Colors.oliveGreen} !important`,
  },
  containerSmall: {
    ...standardPadding.standardWidgetBottom
  },
  maximumWidth: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: props => props.largeTemplate ? 300 : "50%",
    },
  },
  leftGrid: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      justifyContent: props => props.largeTemplate ? "flex-start" : "center",
    }
  },
  rightGrid: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: "22px !important",
    },
    ...paddingAdjustments.mobileOnly.stripVertical
  },
  largeTitle: {
    marginBottom: 18,
  },
  smallTitle: {
  },
  smallSubtitle: {
    marginBottom: "20px !important",
  }
}))

export default ({widget}) => {
  const largeTemplate = widget.template === "Large"
  const xlImageTemplate = widget.template === "Large" && widget.useClinicPadding;
  const classes = useStyles({largeTemplate: largeTemplate});
  return (
    <Grid container spacing={largeTemplate ? 2 : 1} className={clsx(
      widget.useClinicPadding ? "" : classes.containerWidth, classes.container,
      "full-width-image-identifier",
      largeTemplate ? classes.containerLarge : classes.containerSmall)}>
      <Grid item xs={12} md={xlImageTemplate ? 4 : largeTemplate ? 3 : 2} className={clsx(classes.imageGridItem, classes.leftGrid)}>
        <div className={classes.maximumWidth} style={{
          width: "100%",
          background: "transparent",
          zIndex: 1
        }}>
          {
            !!(widget && widget.image && widget.image.fluid) &&
            <Img fluid={widget.image.fluid}/>
          }
        </div>
      </Grid>
      <Grid item xs={12} md={xlImageTemplate ? 8 : widget.template === "Large" ? 9 : 10} className={clsx(classes.rightGrid)}>
        {
          widget.template === "Large" &&
          <Typography variant="h5"
            style={{ marginBottom: "12px", display: "inline" }}>{widget.titlePrefix}</Typography>
        }
        <Typography variant={widget.template === "Large" ? "h2" : "h3"}
                    className={widget.template === "Large" ? classes.largeTitle : classes.smallTitle}
                    >{widget.title}</Typography>
        {
          widget.template === "Small" &&
            <div>
              <Typography variant="h5" className={widget.template === "Large" ? classes.largeTitle : classes.smallSubtitle}>
                {widget.titlePrefix}
              </Typography>
            </div>
        }
        <div className="html__insert" dangerouslySetInnerHTML={{__html: widget.body }}></div>
        {
          widget.accordion &&
            <Accordion
              className="accordion-override"
              active={-1}
              single={true}
              activeColor={"oliveGreen"}
              classes={{
                root: classes.accordionRoot,
                expansionPanelSummary: classes.expansionPanelSummary,
                oliveGreenExpansionPanelSummaryExpanded: classes.ExpansionPanelSummaryExpanded

              }}
              collapses={
                widget.accordion.map(a => (
                  {
                    title: a.title,
                    content: a.text
                  }
                ))
              }
            />
        }
      </Grid>
    </Grid>
  )
}
