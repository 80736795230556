import React from 'react';
import Grid from "@material-ui/core/Grid"
import { makeStyles, Typography } from "@material-ui/core"
import CallbackRequestForm from "./forms/CallbackRequestForm"
import createStyles from "@material-ui/styles/createStyles/createStyles"

import {standardPadding} from "../constants/Spacing"
import GridContainer from "../vendor/mui-pro/src/components/Grid/GridContainer"
import {container} from "../vendor/mui-pro/src/assets/jss/material-kit-pro-react"
import Button from "../vendor/mui-pro/src/components/CustomButtons/Button"
import BackgroundImage from "gatsby-background-image"
import clsx from "clsx"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import useTheme from "@material-ui/core/styles/useTheme"
import { launch88 } from "../hooks/routing"

const responsiveStyles = makeStyles((theme) => createStyles({
  root: {

  },
}));

const outerStyles = makeStyles((theme) => createStyles({
  root: {
    "-webkit-font-smoothing": "antialiased",
   [theme.breakpoints.down('sm')]:{
     ...standardPadding.mobile,
     paddingBottom: 64,

   },
    [theme.breakpoints.up('sm')]:{
     ...standardPadding.desktop,
      paddingBottom: 64,
      paddingTop: 64,
      margin: 0,
    },
  },
  container: {
    ...container,
    justifyContent: "center",
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  leftGrid: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2),
    }
  },
  textContent: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]:{
      maxWidth: "90%",
    },
  },
  responsiveButton: {
    "@media (max-width: 362px)": {
      padding: `12px ${theme.spacing(2)}px`,
    }
  },
  buttonDiv: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(4),
    },
    flexWrap: "wrap"
  },
  firstButton: {
    marginRight: theme.spacing(2),
  },
  secondButton: {
    padding: "12px 16px",
  },
}));


const ContactForm = ({ widget, text, bgImg, hotlinePhoneNumber }) => {
  const classes = responsiveStyles();
  const outerClasses = outerStyles();
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down("xs"));
  const extraExtraSmall = useMediaQuery(theme.breakpoints.down('350px'));

  return(
    <div id="ContactForm">
      <a name="ContactForm" />
    <BackgroundImage
      tag="div"
     className={outerClasses.root}
     fluid={bgImg && bgImg.fluid}
      critical
    >
      <GridContainer className={outerClasses.container}>
        <Grid container spacing={0}
              classes={{
                root: classes.root
              }}
        >
          <Grid className={outerClasses.leftGrid} item justifyContent={"center"} xs={12} sm={7} spacing={0}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%"
            }}>
              <div className={clsx("html__insert body1 inverted", outerClasses.textContent)} dangerouslySetInnerHTML={{__html: text}}></div>
              <div className={outerClasses.buttonDiv} style={{display: "flex", justifyContent: "flex-start"}}>
                <Button onClick={() => {window.location.href = "http://my.idealoption.org/appointments"}} color="primary" className={clsx(outerClasses.responsiveButton, outerClasses.firstButton)}
                  style={{padding: "12px 15px"}} left>
                  <Typography variant="button">
                    Request Appointment
                  </Typography>
                </Button>
                <a href={`tel:${hotlinePhoneNumber}`} rel="noopener" style={{textDecoration: "none"}}>
                  <Button className={clsx(outerClasses.responsiveButton, outerClasses.secondButton)}  color="clear" transparent>
                    <Typography variant="button">
                      Call {hotlinePhoneNumber}
                    </Typography>
                  </Button>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item container direction={"row"} justifyContent={"center"} xs={12} sm={5}>
            { widget && <CallbackRequestForm widget={widget} /> }
          </Grid>
        </Grid>
      </GridContainer>
    </BackgroundImage>
    </div>
  );
}

export default ContactForm;
